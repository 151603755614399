export const agamaOptions = [
    { label: 'Islam', value: 'Islam' },
    { label: 'Protestan', value: 'Protestan' },
    { label: 'Katolik', value: 'Katolik' },
    { label: 'Hindu', value: 'Hindu' },
    { label: 'Buddha', value: 'Buddha' },
    { label: 'Konghucu', value: 'Konghucu' }
];

export const praSchoolOptions = [
    { label: 'Pernah PAUD', value: 'Pernah PAUD' },
    { label: 'Pernah TK/RA', value: 'OraPernah TK/RAnge' },
];

export const imunisasiOptions = [
    { label: 'Hepatitis B', value: 'Hepatitis B' },
    { label: 'Polio', value: 'Polio' },
    { label: 'BCG', value: 'BCG' },
    { label: 'Campak', value: 'Campak' },
    { label: 'DPT', value: 'DPT' },
    { label: 'Covid', value: 'Covid' },
];

export const genderOptions = [
    { label: 'Laki-Laki', value: 'Laki-Laki' },
    { label: 'Perempuan', value: 'Perempuan' },
];

export const statusTempatTinggalOptions = [
    { label: 'Tinggal dengan orang tua/wali', value: 'Tinggal dengan orang tua/wali' },
    { label: 'Ikut saudara/kerabat', value: 'Ikut saudara/kerabat' },
    { label: 'Asrama/Madrasah', value: 'Asrama/Madrasah' },
    { label: 'Kontrak/kos', value: 'Kontrak/kos' },
    { label: 'Tinggal di asrama pesantren', value: 'Tinggal di asrama pesantren' },
    { label: 'Panti asuhan', value: 'Panti asuhan' },
    { label: 'Rumah singgah', value: 'Rumah singgah' },
    { label: 'Lainnya', value: 'Lainnya' },
];

export const statusTempatTinggalOrtuOptions = [
    { label: 'Milik sendiri', value: 'Milik sendiri' },
    { label: 'Rumah Orang tua', value: 'Rumah Orang tua' },
    { label: 'Rumah saudara/kerabat', value: 'Rumah saudara/kerabat' },
    { label: 'Rumah dinas', value: 'Rumah dinas' },
    { label: 'Sewa/kontrak', value: 'Sewa/kontrak' },
    { label: 'Lainnya', value: 'Lainnya' },
];

export const transportasiOptions = [
    { label: 'Jalan kaki', value: 'Jalan kaki' },
    { label: 'Sepeda', value: 'Sepeda' },
    { label: 'Sepeda motor', value: 'Sepeda motor' },
    { label: 'Mobil pribadi', value: 'Mobil pribadi' },
    { label: 'Antar jemput sekolah', value: 'Antar jemput sekolah' },
    { label: 'Angkutan umum', value: 'Angkutan umum' },
    { label: 'Perahu/sampan', value: 'Perahu/sampan' },
    { label: 'Lainnya', value: 'Lainnya' },
];

export const jarakTempuhOptions = [
    { label: 'Kurang dari 1 km', value: 'Kurang dari 1 km' },
    { label: 'Antara 1-5 km', value: 'Antara 1-5 km' },
    { label: 'Antara 6-10 km', value: 'Antara 6-10 km' },
    { label: 'Antara 11-20 km', value: 'Antara 11-20 km' },
    { label: 'Antara 21-30 km', value: 'Antara 21-30 km' },
    { label: 'Lebih dari 30 km', value: 'Lebih dari 30 km' },
];

export const waktuTempuhOptions = [
    { label: '1-19 menit', value: '1-19 menit' },
    { label: '20-39 menit', value: '20-39 menit' },
    { label: '40-59 menit', value: '40-59 menit' },
    { label: '1-2 jam', value: '1-2 jam' },
    { label: '>2 jam', value: '>2 jam' },
];

export const biayaDariOptions = [
    { label: 'Orang tua', value: 'Orang tua' },
    { label: 'Wali/Orang tua asuh', value: 'Wali/Orang tua asuh' },
    { label: 'Tanggungan sendiri', value: 'Tanggungan sendiri' },
    { label: 'Lainnya', value: 'Lainnya' },
];

export const kepemilikanOptions = [
    { label: 'Milik Sendiri', value: 'Milik Sendiri' },
    { label: 'Bukan Milik Sendiri', value: 'Bukan Milik Sendiri' },
];

export const lahanOptions = [
    { label: 'Man 1 Gresik', value: '1' },
];

export const penggunaanOptions = [
    { label: 'Digunakan sebagai ruang kelas utama', value: 'Digunakan sebagai ruang kelas utama' },
    { label: 'Digunakan sebagai ruang kelas tambahan', value: 'Digunakan sebagai ruang kelas tambahan' },
    { label: 'Tidak digunakan sebagai ruang kelas', value: 'Tidak digunakan sebagai ruang kelas' },
];

export const kondisiRuanganOptions = [
    { label: 'Baik', value: 'Baik' },
    { label: 'Rusak Ringan', value: 'Rusak Ringan' },
    { label: 'Rusak Sedang', value: 'Rusak Sedang' },
    { label: 'Rusak Berat', value: 'Rusak Berat' },
    { label: 'Rusak Total/Tidak bisa digunakan', value: 'Rusak Total/Tidak bisa digunakan' },
];

export const kebutuhanKhususOptions = [
    { label: 'Tidak ada', value: 'Tidak ada' },
    { label: 'Lamban belajar', value: 'Lamban belajar' },
    { label: 'Kesulitan belajar spesifik', value: 'Kesulitan belajar spesifik' },
    { label: 'Gangguan komunikasi', value: 'Gangguan komunikasi' },
    { label: 'Berbakat/memiliki kemampuan dan kecerdasan luar biasa', value: 'Berbakat/memiliki kemampuan dan kecerdasan luar biasa' },
    { label: 'Lainnya', value: 'Lainnya' },
];

export const kebutuhanDisabilitasOptions = [
    { label: 'Tidak ada', value: 'Tidak ada' },
    { label: 'Tuna netra', value: 'Tuna netra' },
    { label: 'Tuna rungu', value: 'Tuna rungu' },
    { label: 'Tuna daksa', value: 'Tuna daksa' },
    { label: 'Tuna grahita', value: 'Tuna grahita' },
    { label: 'Tuna Laras', value: 'Tuna Laras' },
    { label: 'Lainnya', value: 'Lainnya' },
];

export const kewarganegaraanOptions = [
    { label: 'WNI (Indonesia)', value: 'WNI (Indonesia)' },
    { label: 'WNA', value: 'WNA' },
];

export const citacitaOptions = [
    { label: 'PNS', value: 'PNS' },
    { label: 'TNI/Polri', value: 'TNI/Polri' },
    { label: 'Guru/Dosen', value: 'Guru/Dosen' },
    { label: 'Dokter', value: 'Dokter' },
    { label: 'Politikus', value: 'Politikus' },
    { label: 'Wiraswasta', value: 'Wiraswasta' },
    { label: 'Seniman/Artis', value: 'Seniman/Artis' },
    { label: 'Ilmuan', value: 'Ilmuan' },
    { label: 'Agamawan', value: 'Agamawan' },
    { label: 'Lainnya', value: 'Lainnya' },
];

export const hobiOptions = [
    { label: 'Olahraga', value: 'Olahraga' },
    { label: 'Kesenian', value: 'Kesenian' },
    { label: 'Membaca', value: 'Membaca' },
    { label: 'Melukis', value: 'Melukis' },
    { label: 'Jalan-Jalan', value: 'Jalan-Jalan' },
    { label: 'Lainnya', value: 'Lainnya' },
];

export const statusKehidupanOptions = [
    { label: 'Masih Hidup', value: 'Masih Hidup' },
    { label: 'Sudah Meninggal', value: 'Sudah Meninggal' },
    { label: 'Tidak Diketahui', value: 'Tidak Diketahui' },
];

export const pendidikanOptions = [
    { label: 'Tidak bersekolah', value: 'Tidak bersekolah' },
    { label: 'SD', value: 'SD' },
    { label: 'SMP/sederajat', value: 'SMP/sederajat' },
    { label: 'SMA/sederajat', value: 'SMA/sederajat' },
    { label: 'D1', value: 'D1' },
    { label: 'D2', value: 'D2' },
    { label: 'D3', value: 'D3' },
    { label: 'D4/S1', value: 'D4/S1' },
    { label: 'S2', value: 'S2' },
    { label: 'S3', value: 'S3' },
    { label: 'Lainnya', value: 'Lainnya' },
];
export const pekerjaanOptions = [
    { label: 'Tidak bekerja', value: 'Tidak bekerja' },
    { label: 'Pensiunan', value: 'Pensiunan' },
    { label: 'PNS', value: 'PNS' },
    { label: 'TNI/Polri', value: 'TNI/Polri' },
    { label: 'Dokter/Bidan/Perawat', value: 'Dokter/Bidan/Perawat' },
    { label: 'Guru/Dosen', value: 'Guru/Dosen' },
    { label: 'Pengacara/Jaksa/Hakin/Notaris', value: 'Pengacara/Jaksa/Hakin/Notaris' },
    { label: 'Pegawai Swasta', value: 'Pegawai Swasta' },
    { label: 'Wiraswasta', value: 'Wiraswasta' },
    { label: 'Seniman/Pelukis/Artis/Sejenisnya', value: 'Seniman/Pelukis/Artis/Sejenisnya' },
    { label: 'Pilot/Pramugari', value: 'Pilot/Pramugari' },
    { label: 'Sopir/Masinis/Kondektur', value: 'Sopir/Masinis/Kondektur' },
    { label: 'Pedagang', value: 'Pedagang' },
    { label: 'Petani/Peternak', value: 'Petani/Peternak' },
    { label: 'Nelayan', value: 'Nelayan' },
    { label: 'Buruh(Tani/Pabrik/Bangunan)', value: 'Buruh(Tani/Pabrik/Bangunan)' },
    { label: 'Politikus', value: 'Politikus' },
    { label: 'Lainnya', value: 'Lainnya' },
];
export const penghasilanOptions = [
    { label: 'Tidak berpenghasilan', value: 'Tidak berpenghasilan' },
    { label: 'Kurang dari Rp 500.000', value: 'Kurang dari Rp 500.000' },
    { label: 'Rp 500.000 - Rp 1.000.000', value: 'Rp 500.000 - Rp 1.000.000' },
    { label: 'Rp 1.000.001 - Rp 2.000.000', value: 'Rp 1.000.001 - Rp 2.000.000' },
    { label: 'Rp 2.000.001 - Rp 3.000.000', value: 'Rp 2.000.001 - Rp 3.000.000' },
    { label: 'Rp 3.000.001 - Rp 5.000.000', value: 'Rp 3.000.001 - Rp 5.000.000' },
    { label: 'Lebih dari Rp 5.000.000', value: 'Lebih dari Rp 5.000.000' },
    { label: 'Lainnya', value: 'Lainnya' },
];
export const domisiliOptions = [
    { label: 'Dalam Negeri', value: 'Dalam Negeri' },
    { label: 'Luar Negeri', value: 'Luar Negeri' },
];

export const jenisPendaftaranOptions = [
    { label: 'Siswa Baru', value: 'Siswa Baru' },
    { label: 'Masuk Kembali', value: 'Masuk Kembali' },
    { label: 'Mutasi Masuk', value: 'Mutasi Masuk' },
];

export const alasanKeluarOptions = [
    { label: 'Lulus', value: 'Lulus' },
    { label: 'Mutasi', value: 'Mutasi' },
    { label: 'Mengundurkan Diri', value: 'Mengundurkan Diri' },
    { label: 'Putus Sekolah', value: 'Putus Sekolah' },
    { label: 'Dikeluarkan', value: 'Dikeluarkan' },
    { label: 'Meninggal Dunia', value: 'Meninggal Dunia' },
    { label: 'Lainnya', value: 'Lainnya' },
];

export const jenisInstansiOptions = [
    { label: 'Kementerian agama', value: 'Kementerian agama' },
    { label: 'Kementerian lain', value: 'Kementerian lain' },
    { label: 'Pemerintah daerah', value: 'Pemerintah daerah' },
    { label: 'BUMN', value: 'BUMN' },
    { label: 'BUMD', value: 'BUMD' },
    { label: 'Instansi swasta', value: 'Instansi swasta' },
    { label: 'Yayasan', value: 'Yayasan' },
    { label: 'Perorangan', value: 'Perorangan' },
    { label: 'Lainnya', value: 'Lainnya' },
];

export const beasiswaKategoriOptions = [
    { label: 'Beasiswa Berprestasi', value: 'Beasiswa Berprestasi' },
    { label: 'Beasiswa Komite', value: 'Beasiswa Komite' },
    { label: 'Beasiswa kurang mampu/miskin', value: 'Beasiswa kurang mampu/miskin' },
    { label: 'Beasiswa miskin dan berprestasi', value: 'Beasiswa miskin dan berprestasi' },
    { label: 'Beasiswa Lainnya', value: 'Beasiswa Lainnya' },
];

export const bidangLombaOptions = [
    { label: 'Akademik', value: 'Akademik' },
    { label: 'Pramuka/Paskibraka,', value: 'Pramuka/Paskibraka,' },
    { label: 'Pidato bahasa asing', value: 'Pidato bahasa asing' },
    { label: 'Sains', value: 'Sains' },
    { label: 'Keagamaan', value: 'Keagamaan' },
    { label: 'Karya ilmiah', value: 'Karya ilmiah' },
    { label: 'Olahraga', value: 'Olahraga' },
    { label: 'Kesenian', value: 'Kesenian' },
    { label: 'Lainnya', value: 'Lainnya' },
];

export const tingkatLombaOptions = [
    { label: 'Kabupaten/Kota', value: 'Kabupaten/Kota' },
    { label: 'Provinsi', value: 'Provinsi' },
    { label: 'Nasional', value: 'Nasional' },
    { label: 'Internasional', value: 'Internasional' },
];

export const peringkatLombaOptions = [
    { label: 'Tidak meraih juara', value: 'Tidak meraih juara' },
    { label: 'Juara I/Medali emas', value: 'Juara I/Medali emas' },
    { label: 'Juara II/Medali perak', value: 'Juara II/Medali perak' },
    { label: 'Juara III/Medali perunggu', value: 'Juara III/Medali perunggu' },
    { label: 'Harapan I', value: 'Harapan I' },
    { label: 'Harapan II', value: 'Harapan II' },
    { label: 'Harapan III', value: 'Harapan III' },
    { label: 'Juara Favorit', value: 'Juara Favorit' },
    { label: 'Lainnya', value: 'Lainnya' },
];

export const frekuensiOptions = [
    { label: 'Setiap Hari', value: 'Setiap Hari' },
    { label: 'Seminggu 2-3 kali', value: 'Seminggu 2-3 kali' },
    { label: 'Seminggu sekali', value: 'Seminggu sekali' },
    { label: 'Tidak rutin', value: 'Tidak rutin' },
];

export const jenisBerkasOptions = [
    { label: 'KK', value: 'KK' },
    { label: 'Akta Kelahiran', value: 'Akta Kelahiran' },
    { label: 'Ijazah', value: 'Ijazah' },
    { label: 'Surat Keterangan', value: 'Surat Keterangan' },
    { label: 'KIP', value: 'KIP' },
    { label: 'KKS', value: 'KKS' },
    { label: 'PKH', value: 'PKH' },
    { label: 'Lainnya', value: 'Lainnya' },
];

export const kategoriGeografisOptions = [
    { label: 'Pesisir Pantai', value: 'Pesisir Pantai' },
    { label: 'Dataran Rendah', value: 'Dataran Rendah' },
    { label: 'Pegunungan', value: 'Pegunungan' },
];

export const wilayahOptions = [
    { label: 'Pedesaan', value: 'Pedesaan' },
    { label: 'Desa Transisi', value: 'Desa Transisi' },
    { label: 'Terpencil/Terbelakang', value: 'Terpencil/Terbelakang' },
    { label: 'Masyaraka Adat', value: 'Masyaraka Adat' },
    { label: 'Perbatasan Negara', value: 'Perbatasan Negara' },
    { label: 'Rawan Bencana Alam/Sosial', value: 'Rawan Bencana Alam/Sosial' },
    { label: 'Perkotaan', value: 'Perkotaan' },
];

export const potensiWilayahOptions = [
    { label: 'Pertanian', value: 'Pertanian' },
    { label: 'Kehutanan/Perkebunan', value: 'Kehutanan/Perkebunan' },
    { label: 'Industri', value: 'Industri' },
    { label: 'Pertambangan', value: 'Pertambangan' },
    { label: 'Lainnya', value: 'Lainnya' },
];

export const jarakKeLokasiOptions = [
    { label: '< 1 km', value: '< 1 km' },
    { label: '1 – 10 km', value: '1 – 10 km' },
    { label: '11 – 30 km', value: '11 – 30 km' },
    { label: '31 – 50 km', value: '31 – 50 km' },
    { label: '> 50 km', value: '> 50 km' },
];

export const statusKepegawaianOptions = [
    { label: 'PNS', value: 'PNS' },
    { label: 'Non-PNS', value: 'Non-PNS' },
];
export const statusPernikahanOptions = [
    { label: 'Kawin', value: 'Kawin' },
    { label: 'Belum Kawin', value: 'Belum Kawin' },
    { label: 'Duda/Janda', value: 'Duda/Janda' },
];
export const statusTempatTinggalGuruOptions = [
    { label: 'Milik Sendiri', value: 'Milik Sendiri' },
    { label: 'Rumah Orang Tua', value: 'Rumah Orang Tua' },
    { label: 'Rumah Saudara/Kerabat', value: 'Rumah Saudara/Kerabat' },
    { label: 'Rumah Dinas', value: 'Rumah Dinas' },
    { label: 'Sewa/Kontrak', value: 'Sewa/Kontrak' },
    { label: 'Lainnya', value: 'Lainnya' },
];
export const penugasanOptions = [
    { label: 'Guru', value: 'Guru' },
    { label: 'Tenaga Kependidikan', value: 'Tenaga Kependidikan' },
];
export const statusPenugasanOptions = [
    { label: 'Guru/Pegawai Tetap', value: 'Guru/Pegawai Tetap' },
    { label: 'Guru/Pegawai Tidak Tetap', value: 'Guru/Pegawai Tidak Tetap' },
    { label: 'Guru/Pegawai Diperbantukan', value: 'Guru/Pegawai Diperbantukan' },
    { label: 'Guru/Pegawai Dipekerjakan', value: 'Guru/Pegawai Dipekerjakan' },
];
export const golRuangKepangkatanOptions = [
    { label: 'Tidak Ada Golongan', value: 'Tidak Ada Golongan' },
    { label: 'I/a – I/d', value: 'I/a – I/d' },
    { label: 'II/a', value: 'II/a' },
    { label: 'II/b', value: 'II/b' },
    { label: 'II/c', value: 'II/c' },
    { label: 'II/d', value: 'II/d' },
    { label: 'III/a', value: 'III/a' },
    { label: 'III/b', value: 'III/b' },
    { label: 'III/c', value: 'III/c' },
    { label: 'III/d', value: 'III/d' },
    { label: 'IV/a', value: 'IV/a' },
    { label: 'IV/b', value: 'IV/b' },
    { label: 'IV/c', value: 'IV/c' },
    { label: 'IV/d', value: 'IV/d' },
    { label: 'IV/e', value: 'IV/e' },
];

export const statusKeaktifanOptions = [
    { label: 'Aktif', value: 'Aktif' },
    { label: 'Tidak Aktif', value: 'Tidak Aktif' },
];
export const jenisSKOptions = [
    { label: 'SK CPNS', value: 'SK CPNS' },
    { label: 'SK Kenaikan Pangkat PNS (Reguler)', value: 'SK Kenaikan Pangkat PNS (Reguler)' },
    { label: 'SK Kenaikan', value: 'SK Kenaikan' },
    { label: 'Pangkat PNS (Pilihan)', value: 'Pangkat PNS (Pilihan)' },
    { label: 'SK Non-PNS', value: 'SK Non-PNS' },
    { label: 'SK Yayasan', value: 'SK Yayasan' },
    { label: 'SKPBM', value: 'SKPBM' },
    { label: 'SK Lainnya', value: 'SK Lainnya' },
];

// TUGAS OPTION
export const tugasJenisAktivitasOptions = [
    { label: 'Pembelajaran', value: 'Pembelajaran' },
    { label: 'Kependidikan', value: 'Kependidikan' },
];
export const tugasJenisTugasPembelajaranOptions = [
    { label: 'Guru BK', value: 'Guru BK' },
    { label: 'Guru inklusi', value: 'Guru inklusi' },
    { label: 'Guru ubudiyah', value: 'Guru ubudiyah' },
];
export const tugasJenisTugasPembelajaranNonSatminkalOptions = [
    { label: 'Guru mapel', value: 'Guru mapel' },
    { label: 'Guru BK', value: 'Guru BK' },
    { label: 'Guru inklusi', value: 'Guru inklusi' },
];

export const tugasJenisTugasPendidikanOptions = [
    { label: 'Kepala Madrasah', value: 'Kepala Madrasah' },
    { label: 'Wakil Kepala Kurikulum', value: 'Wakil Kepala Kurikulum' },
    { label: 'Waka Kesiswaan', value: 'Waka Kesiswaan' },
    { label: 'Waka Sarpras', value: 'Waka Sarpras' },
    { label: 'Waka Humas', value: 'Waka Humas' },
    { label: 'Waka Litbang', value: 'Waka Litbang' },
    { label: 'Kepala perpustakaan', value: 'Kepala perpustakaan' },
    { label: 'Kepala laboratorium', value: 'Kepala laboratorium' },
    { label: 'Ketua jurusan/program keahlian', value: 'Ketua jurusan/program keahlian' },
    { label: 'Kepala bengkel', value: 'Kepala bengkel' },
    { label: 'Pembimbing praktek kerja industri', value: 'Pembimbing praktek kerja industri' },
    { label: 'Kepala unit produksi', value: 'Kepala unit produksi' },
    { label: 'Pembimbing ekstrakulikuler', value: 'Pembimbing ekstrakulikuler' },
    { label: 'Riset', value: 'Riset' },
    { label: 'Tata usaha', value: 'Tata usaha' },
    { label: 'Pustakawan', value: 'Pustakawan' },
    { label: 'Laboran', value: 'Laboran' },
    { label: 'Petugas kebersihan', value: 'Petugas kebersihan' },
    { label: 'Pengemudi', value: 'Pengemudi' },
    { label: 'Penjaga sekolah', value: 'Penjaga sekolah' },
    { label: 'Tenaga keamanan', value: 'Tenaga keamanan' },
    { label: 'Operator data', value: 'Operator data' },
    { label: 'Teknisi workshop/bengkel', value: 'Teknisi workshop/bengkel' },
    { label: 'Bendahara', value: 'Bendahara' },
    { label: 'Kepala laboratorium IPA', value: 'Kepala laboratorium IPA' },
    { label: 'Kepala laboratorium Komputer', value: 'Kepala laboratorium Komputer' },
    { label: 'Kepala laboratorium Bahasa', value: 'Kepala laboratorium Bahasa' },
    { label: 'Lainnya', value: 'Lainnya' },
];


export const golDarahOptions = [
    { label: 'A', value: 'A' },
    { label: 'B', value: 'B' },
    { label: 'AB', value: 'AB' },
    { label: 'O', value: 'O' },
];
export const jenisPerubahanOptions = [
    { label: 'Fungsi/Jabatan', value: 'Fungsi/Jabatan' },
    { label: 'Status Penugasan', value: 'Status Penugasan' },
    { label: 'Golongan', value: 'Golongan' },
    { label: 'Status Keaktifan', value: 'Status Keaktifan' },
    { label: 'Pegawai Baru', value: 'Pegawai Baru' },
];
export const statusSekolahOptions = [
    { label: 'Negeri', value: 'Negeri' },
    { label: 'Status Penugasan', value: 'Status Penugasan' },
    { label: 'Golongan', value: 'Golongan' },
    { label: 'Status Keaktifan', value: 'Status Keaktifan' },
    { label: 'Pegawai Baru', value: 'Pegawai Baru' },
];
export const pelatihanBidangOptions = [
    { label: 'Kompetensi Pembelajaran/Pedagogik', value: 'Kompetensi Pembelajaran/Pedagogik' },
    { label: 'Kompetensi Kepribadian', value: 'Kompetensi Kepribadian' },
    { label: 'Kompetensi Manjerial', value: 'Kompetensi Manjerial' },
    { label: 'Kompetensi Kewirausahaan', value: 'Kompetensi Kewirausahaan' },
];
export const pelatihanPenyelenggaraOptions = [
    { label: 'Kemenag Pusat', value: 'Kemenag Pusat' },
    { label: 'Kemenag Provinsi', value: 'Kemenag Provinsi' },
    { label: 'Kemenag Kab/Kota', value: 'Kemenag Kab/Kota' },
    { label: 'Kementrian Pendidikan dan Kebudayaan', value: 'Kementrian Pendidikan dan Kebudayaan' },
    { label: 'Dinas Pendidikan Provinsi', value: 'Dinas Pendidikan Provinsi' },
    { label: 'Dinas Pendidikan Kab/Kota', value: 'Dinas Pendidikan Kab/Kota' },
    { label: 'Lainnya', value: 'Lainnya' },
];
export const statusSertifikasiOptions = [
    { label: 'Mengikuti', value: 'Mengikuti' },
    { label: 'Tidak Mengikuti', value: 'Tidak Mengikuti' },
];
export const statusKelulusanOptions = [
    { label: 'Belum Lulus', value: 'Belum Lulus' },
    { label: 'Masih Proses', value: 'Masih Proses' },
    { label: 'Lulus', value: 'Lulus' },
];
export const profesiDidapatkanOptions = [
    { label: 'Pendidikan Agama Islam (PAI)', value: 'Pendidikan Agama Islam (PAI)' },
    { label: 'Al Qurán Hadist', value: 'Al Qurán Hadist' },
    { label: 'Aqidah Akhlak', value: 'Aqidah Akhlak' },
    { label: 'Fiqih', value: 'Fiqih' },
    { label: 'Sejarah Kebudayaan Islam (SKI)', value: 'Sejarah Kebudayaan Islam (SKI)' },
    { label: 'Pendidikan', value: 'Pendidikan' },
    { label: 'Kewarganegaraan (PKn)', value: 'Kewarganegaraan (PKn)' },
    { label: 'Bahasa Indonesia', value: 'Bahasa Indonesia' },
    { label: 'Bahasa Arab', value: 'Bahasa Arab' },
    { label: 'Bahasa Inggris', value: 'Bahasa Inggris' },
    { label: 'Bahasa Asing Lainnya', value: 'Bahasa Asing Lainnya' },
    { label: 'Matematika', value: 'Matematika' },
    { label: 'IPA', value: 'IPA' },
    { label: 'Fisika', value: 'Fisika' },
    { label: 'Biologi', value: 'Biologi' },
    { label: 'Kimia', value: 'Kimia' },
    { label: 'IPS', value: 'IPS' },
    { label: 'Sejarah/Sejarah Nasional dan Umum', value: 'Sejarah/Sejarah Nasional dan Umum' },
    { label: 'Geografi', value: 'Geografi' },
    { label: 'Ekonomi/Akuntansi', value: 'Ekonomi/Akuntansi' },
    { label: 'Sosiologi Antropologi', value: 'Sosiologi Antropologi' },
    { label: 'Sosiologi', value: 'Sosiologi' },
    { label: 'Antropologi', value: 'Antropologi' },
    { label: 'Tata Negara', value: 'Tata Negara' },
    { label: 'Seni Budaya & Keterampilan/Kerajinan Tangan dan Kesenian', value: 'Seni Budaya & Keterampilan/Kerajinan Tangan dan Kesenian' },
    { label: 'Seni Budaya', value: 'Seni Budaya' },
    { label: 'Keterampilan', value: 'Keterampilan' },
    { label: 'Pendidikan Jasmani Olahraga & Kesehatan', value: 'Pendidikan Jasmani Olahraga & Kesehatan' },
    { label: 'Teknologi Informasi dan Komunikasi (TIK)', value: 'Teknologi Informasi dan Komunikasi (TIK)' },
    { label: 'Muatan Lokal', value: 'Muatan Lokal' },
    { label: 'Umum/Ilmu Pengetahuan dan Teknologi', value: 'Umum/Ilmu Pengetahuan dan Teknologi' },
    { label: 'Muatan Lokal Agama', value: 'Muatan Lokal Agama' },
    { label: 'dan Akhlak', value: 'dan Akhlak' },
    { label: 'Bimbingan Konseling/Bimbingan Penyuluhan', value: 'Bimbingan Konseling/Bimbingan Penyuluhan' },
    { label: 'Guru', value: 'Guru' },
    { label: 'Kelas', value: 'Kelas' },
    { label: 'Muatan Lokal Kewarganegaraan dan Kepribadian', value: 'Muatan Lokal Kewarganegaraan dan Kepribadian' },
    { label: 'Muatan Lokal Pelajaran Estetika', value: 'Muatan Lokal Pelajaran Estetika' },
    { label: 'Muatan Lokal Pelajaran', value: 'Muatan Lokal Pelajaran' },
    { label: 'Jasmani Olahraga dan Kesehatan', value: 'Jasmani Olahraga dan Kesehatan' },
    { label: 'Muatan Lokal Bahasa', value: 'Muatan Lokal Bahasa' },
    { label: 'Prakarya', value: 'Prakarya' },
    { label: 'Ilmu Kalam', value: 'Ilmu Kalam' },
    { label: 'Ilmu Hadist', value: 'Ilmu Hadist' },
    { label: 'Ilmu Tafsir', value: 'Ilmu Tafsir' },
    { label: 'Tematik', value: 'Tematik' },
    { label: 'BTQ', value: 'BTQ' },
    { label: 'Lainnya', value: 'Lainnya' },
];

export const anakPendidikanOptions = [
    { label: 'Belum Sekolah', value: 'Belum Sekolah' },
    { label: 'TK/RA Sederajat', value: 'TK/RA Sederajat' },
    { label: 'SD/MI Sederajat', value: 'SD/MI Sederajat' },
    { label: 'SMP/MTS Sederajat', value: 'SMP/MTS Sederajat' },
    { label: 'SMA/MA Sederajat', value: 'SMA/MA Sederajat' },
    { label: 'Perguruan Tinggi', value: 'Perguruan Tinggi' },
];
export const anakPekerjaanOptions = [
    { label: 'Belum Sekolah', value: 'Belum Sekolah' },
    { label: 'Pelajar', value: 'Pelajar' },
    { label: 'PNS', value: 'PNS' },
    { label: 'Pegawai Swasta', value: 'Pegawai Swasta' },
    { label: 'Wiraswasta', value: 'Wiraswasta' },
    { label: 'Lainnya', value: 'Lainnya' },
];
export const anakStatusOptions = [
    { label: 'Anak Kandung', value: 'Anak Kandung' },
    { label: 'Anak Tiri', value: 'Anak Tiri' },
    { label: 'Anak Angkat', value: 'Anak Angkat' },
];
export const riwayatPesantrenKosentrasiOptions = [
    { label: 'Tafsir', value: 'Tafsir' },
    { label: 'Hadist', value: 'Hadist' },
    { label: 'Faraidh', value: 'Faraidh' },
    { label: 'Ilmu Kalam', value: 'Ilmu Kalam' },
    { label: 'Akhlaq', value: 'Akhlaq' },
    { label: 'Nahwu Sharaf', value: 'Nahwu Sharaf' },
    { label: 'Ilmu Falaq', value: 'Ilmu Falaq' },
];
export const semesterOptions = [
    { label: 'Ganjil', value: 'Ganjil' },
    { label: 'Genap', value: 'Genap' },
];

export const pendidikanFormalJenjangOptions = [
    { label: 'SD/Sederajat', value: 'SD/Sederajat' },
    { label: 'SMP/Sederajat', value: 'SMP/Sederajat' },
    { label: 'SMA/Sederajat', value: 'SMA/Sederajat' },
    { label: 'D1', value: 'D1' },
    { label: 'D2', value: 'D2' },
    { label: 'D3', value: 'D3' },
    { label: 'D4/S1', value: 'D4/S1' },
    { label: 'S2', value: 'S2' },
    { label: 'S3', value: 'S3' },
];

export const pendidikanFormalStatusLembagaOptions = [
    { label: 'Negeri', value: 'Negeri' },
    { label: 'Swasta', value: 'Swasta' },
];

export const penghargaanJenisPenghargaanOption = [
    { label: 'Guru Berprestasi', value: 'Guru Berprestasi' },
    { label: 'Tenaga Pendidik Berprestasi', value: 'Tenaga Pendidik Berprestasi' },
    { label: 'Tenaga Pendidik Berprestasi', value: 'Tenaga Pendidik Berprestasi' },
    { label: 'ICT/TIK', value: 'ICT/TIK' },
    { label: 'Penelitian Tindakan Kelas (PTK)', value: 'Penelitian Tindakan Kelas (PTK)' },
    { label: 'Model Pembelajaran', value: 'Model Pembelajaran' },
    { label: 'Metode Pembelajaran', value: 'Metode Pembelajaran' },
    { label: 'Media Pembelajaran', value: 'Media Pembelajaran' },
    { label: 'Lainnya', value: 'Lainnya' },
];
export const penghargaanPenyelenggaraOptions = [
    { label: 'Kementrian Agama', value: 'Kementrian Agama' },
    { label: 'Pemerintah Daerah', value: 'Pemerintah Daerah' },
    { label: 'Perguruan Tinggi', value: 'Perguruan Tinggi' },
    { label: 'Lainnya', value: 'Lainnya' },
];
export const penghargaanTingkatPenghargaanOptions = [
    { label: 'Guru Berprestasi', value: 'Guru Berprestasi' },
    { label: 'Tenaga Pendidik Berprestasi', value: 'Tenaga Pendidik Berprestasi' },
    { label: 'ICT/TIK', value: 'ICT/TIK' },
    { label: 'Penelitian Tindakan Kelas (PTK)', value: 'Penelitian Tindakan Kelas (PTK)' },
    { label: 'Model Pembelajaran', value: 'Model Pembelajaran' },
    { label: 'Metode', value: 'Metode' },
    { label: 'Pembelajaran', value: 'Pembelajaran' },
    { label: 'Media Pembelajaran', value: 'Media Pembelajaran' },
    { label: 'Lainnya', value: 'Lainnya' },
];
export const penghargaanPeringkatOptions = [
    { label: 'Peringkat I', value: 'Peringkat I' },
    { label: 'Peringkat II', value: 'Peringkat II' },
    { label: 'Peringkat III', value: 'Peringkat III' },
    { label: 'Lainnya', value: 'Lainnya' },
];
export const mataPelajaranKelompokOptions = [
    { label: 'B', value: 'B' },
    { label: 'C', value: 'C' },
];
export const mataPelajaranKelompokBOptions = [
    { label: 'Tanpa Induk', value: 'Tanpa Induk' },
    { label: 'Seni Budaya', value: 'Seni Budaya' },
    { label: 'Prakarya dan Kewirausahaan', value: 'Prakarya dan Kewirausahaan' },
    { label: 'Muatan Lokal', value: 'Muatan Lokal' },
];
export const mataPelajaranKelompokCOptions = [
    { label: 'Tanpa Induk', value: 'Tanpa Induk' },
    { label: 'Matematika', value: 'Matematika' },
    { label: 'Biologi', value: 'Biologi' },
    { label: 'Fisika', value: 'Fisika' },
    { label: 'Kimia', value: 'Kimia' },
    { label: 'Geografi', value: 'Geografi' },
    { label: 'Sejarah', value: 'Sejarah' },
    { label: 'Sosiaologi', value: 'Sosiaologi' },
    { label: 'Ekonomi', value: 'Ekonomi' },
    { label: 'Bahasa dan Sastra Indonesia', value: 'Bahasa dan Sastra Indonesia' },
    { label: 'Bahasa dan Sastra Inggris', value: 'Bahasa dan Sastra Inggris' },
    { label: 'Bahasa dan Sastra Asing Lainnya', value: 'Bahasa dan Sastra Asing Lainnya' },
    { label: 'Antropologi', value: 'Antropologi' },
    { label: 'Ilmu Tafsir', value: 'Ilmu Tafsir' },
    { label: 'Ilmu Hadist', value: 'Ilmu Hadist' },
    { label: 'Ushul Fikih', value: 'Ushul Fikih' },
    { label: 'Bahasa Arab', value: 'Bahasa Arab' },
    { label: 'Mata Pelajaran Pilihan', value: 'Mata Pelajaran Pilihan' },
];
export const mataPelajaranJurusanOptions = [
    { label: 'Umum', value: 'Umum' },
    { label: 'IPA', value: 'IPA' },
    { label: 'IPS', value: 'IPS' },
    { label: 'BAHASA', value: 'BAHASA' },
    { label: 'Keagamaan', value: 'Keagamaan' },
];


// LEMBAGA OPTIONS
export const lembagaStatusOptions = [
    { label: 'Negeri', value: 'Negeri' },
    { label: 'Swasta', value: 'Swasta' },
];
export const lembagaWaktuBelajarOptions = [
    { label: 'Pagi', value: 'Pagi' },
    { label: 'Siang', value: 'Siang' },
    { label: 'Sore', value: 'Sore' },
    { label: 'Malam', value: 'Malam' },
];

export const lembagaStatusKelompokOptions = [
    { label: 'Induk', value: 'Induk' },
    { label: 'Cabang', value: 'Cabang' },
];

export const lembagaKomiteOptions = [
    { label: 'Terbentuk', value: 'Terbentuk' },
    { label: 'Belum Terbentuk', value: 'Belum Terbentuk' },
];
export const lembagaDokumenJenisOptions = [
    { label: 'SK Pendirian', value: 'SK Pendirian' },
    { label: 'Surat Izin Operasioal', value: 'Surat Izin Operasioal' },
    { label: 'Dokumen NSM', value: 'Dokumen NSM' },
    { label: 'Dokumen NPSN', value: 'Dokumen NPSN' },
    { label: 'SK Kemenkumham', value: 'SK Kemenkumham' },
    { label: 'Surat Izin', value: 'Surat Izin' },
    { label: 'Dokumen Akreditasi', value: 'Dokumen Akreditasi' },
    { label: 'dokumen lainnya', value: 'dokumen lainnya' },
];

export const jenjangSekolahAsalOptions = [
    { label: 'MTS Negeri', value: 'MTS Negeri' },
    { label: 'MTS Swasta', value: 'MTS Swasta' },
    { label: 'SMP Negeri', value: 'SMP Negeri' },
    { label: 'SMP Swasta', value: 'SMP Swasta' },
]

// OPTIONS ROMBEL
export const jenisRombelOptions = [
    { label: 'Kelas', value: 'Kelas' },
    { label: 'SKS', value: 'SKS' },
]
export const tingkatRombelOptions = [
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
]

// OPTIONS ANGGOTA SISWA
export const jenisPendaftaranAnggotaSiswaOptions = [
    { label: 'Siswa baru', value: 'Siswa baru' },
    { label: 'Naik kelas', value: 'Naik kelas' },
    { label: 'Mengulang', value: 'Mengulang' },
    { label: 'Lainnya', value: 'Lainnya' },
]

// OPTIONS PEMBELAJARAN
export const jenisTugasOptions = [
    { label: 'Tugas Utama', value: 'Tugas Utama' },
    { label: 'Tugas Tambahan Satminkal', value: 'Tugas Tambahan Satminkal' },
]
export const daysOptions = [
    { label: 'Senin', value: 'Senin' },
    { label: 'Selasa', value: 'Selasa' },
    { label: 'Rabu', value: 'Rabu' },
    { label: 'Kamis', value: 'Kamis' },
    { label: 'Jumat', value: 'Jumat' },
    { label: 'Sabtu', value: 'Sabtu' },
    { label: 'Minggu', value: 'Minggu' },
]

// OPTIONS GTK/PTK
export const alasanKeluarGuTendOptions = [
    { label: 'Mutasi', value: 'Mutasi' },
    { label: 'Mengundurkan Diri', value: 'Mengundurkan Diri' },
    { label: 'Dikeluarkan', value: 'Dikeluarkan' },
    { label: 'Meninggal Dunia', value: 'Meninggal Dunia' },
    { label: 'Lainnya', value: 'Lainnya' },
];

// OPTIONS TATIBSI
export const aspekPelanggaranOptions = [
    { label: 'Kehadiran', value: 'Kehadiran' },
    { label: 'Kegiatan Belajar Mengajar', value: 'Kegiatan Belajar Mengajar' },
    { label: 'Pakaian Seragam/Kerapihan', value: 'Pakaian Seragam/Kerapihan' },
    { label: 'Sikap dan Perilaku', value: 'Sikap dan Perilaku' },
    { label: 'Kegiatan Keagamaan', value: 'Kegiatan Keagamaan' },
];
export const bintangPenghargaanOptions = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
];

// OPTIONS BIMBINGAN KONSELING
export const hasilTesKategoriOptions = [
    { label: 'Tes Psikologi', value: 'Tes Psikologi' },
    { label: 'Tes Peminatan Siswa', value: 'Tes Peminatan Siswa' },
    { label: 'Tes Minat dan Bakat', value: 'Tes Minat dan Bakat' },
    { label: 'Tes IQ/EQ', value: 'Tes IQ/EQ' },
    { label: 'Tes Kepribadian', value: 'Tes Kepribadian' },
    { label: 'Tes Lainnya', value: 'Tes Lainnya' },
];
export const jenisJalurOptions = [
    { label: 'Undangan', value: 'Undangan' },
    { label: 'Tes Tulis', value: 'Tes Tulis' },
];
export const tipeLaporanOptions = [
    { label: 'Siswa', value: 'Siswa' },
    { label: 'Wali', value: 'Wali' },
];

// OPTIONS TAHUN AJARAN
export const tipeSemesterOptions = [
    { label: 'Ganjil', value: 'Ganjil' },
    { label: 'Genap', value: 'Genap' },
];

// OPTIONS ALUMNI
export const alumniFilterCategoryOptions = [
    { label: 'Registrasi Alumni', value: 'Registrasi Alumni' },
    { label: 'Diluluskan Sistem', value: 'Diluluskan Sistem' },
];
export const alumniCategoryOptions = [
    { label: 'Dalam Pendidikan', value: 'Dalam Pendidikan' },
    { label: 'Bekerja', value: 'Bekerja' },
];
export const alumniPendidikanOptions = [
    { label: 'SMA/sederajat', value: 'SMA/sederajat' },
    { label: 'D1', value: 'D1' },
    { label: 'D2', value: 'D2' },
    { label: 'D3', value: 'D3' },
    { label: 'D4/S1', value: 'D4/S1' },
    { label: 'S2', value: 'S2' },
    { label: 'S3', value: 'S3' },
    { label: 'Lainnya', value: 'Lainnya' },
];

// OPTIONS KONTEN
export const kontenFilterCategoryOptions = [
    { label: 'Siswa', value: 'Siswa' },
    { label: 'Guru', value: 'Guru' },
    { label: 'Madrasah', value: 'Madrasah' },
];

// OPTIONS RIWAYAT BANTUAN
// export const jenisBantuanOptions = [
//     { label: 'BOP', value: 'BOP' },
//     { label: 'BOS', value: 'BOS' },
//     { label: 'BPDGS', value: 'BPDGS' },
//     { label: 'Jasmas', value: 'Jasmas' },
//     { label: 'Afirmasi', value: 'Afirmasi' },
//     { label: 'Kinerja', value: 'Kinerja' },
//     { label: 'Lainnya', value: 'Lainnya' },
// ];

// export const sumberBantuanOptions = [
//     { label: 'Pusat/APBN', value: 'Pusat/APBN' },
//     { label: 'BOS', value: 'BOS' },
//     { label: 'Provinsi', value: 'Provinsi' },
//     { label: '(Kab, Kota/APBD)', value: '(Kab, Kota/APBD)' },
// ];


// OPTIONS SANITASI AIR
export const sumberAirOptions = [
    { label: 'Air Kemasan', value: 'Air Kemasan' },
    { label: 'Ledeng/PAM', value: 'Ledeng/PAM' },
    { label: 'Pompa', value: 'Pompa' },
    { label: 'Sumur Terlindungi', value: 'Sumur Terlindungi' },
    { label: 'Mata Air Terlindungi', value: 'Mata Air Terlindungi' },
    { label: 'Air Hujan', value: 'Air Hujan' },
    { label: 'Air Sungai', value: 'Air Sungai' },
    { label: 'Sumur Tidak Terlindungi', value: 'Sumur Tidak Terlindungi' },
    { label: 'Mata Air Tidak Terlindungi', value: 'Mata Air Tidak Terlindungi' },
    { label: 'Tidak Ada Sumber Air', value: 'Tidak Ada Sumber Air' },
];

export const sumberAirMinumOptions = [
    { label: 'Disediakan oleh Madrasah', value: 'Disediakan oleh Madrasah' },
    { label: 'Disediakan Oleh Siswa', value: 'Disediakan Oleh Siswa' },
    { label: 'Tidak Ada', value: 'Tidak Ada' },
];

export const kecukupanAirBersihOptions = [
    { label: 'Cukup Setiap Saat', value: 'Cukup Setiap Saat' },
    { label: 'Cukup Tapi Tidak Setiap Saat', value: 'Cukup Tapi Tidak Setiap Saat' },
];

export const tipeToiletOptions = [
    { label: 'Leher Angsa (Jamban Duduk/Jongkok)', value: 'Leher Angsa (Jamban Duduk/Jongkok)' },
    { label: 'Cubluk Dengan Tutup', value: 'Cubluk Dengan Tutup' },
    { label: 'Jamban Menggantung Di Atas Sungai', value: 'Jamban Menggantung Di Atas Sungai' },
    { label: 'Cubluk Tanpa Tutup', value: 'Cubluk Tanpa Tutup' },
    { label: 'Tidak Tersedia Jamban', value: 'Tidak Tersedia Jamban' },
];

export const pembalutCadanganOptions = [
    { label: 'Tidak', value: 'Tidak' },
    { label: 'Menyediakan dengan cara siswi harus membeli', value: 'Menyediakan dengan cara siswi harus membeli' },
    { label: 'Menyediakan dengan cara memberikan secara gratis', value: 'Menyediakan dengan cara memberikan secara gratis' },
];

export const saluranPembuanganAirLimbahOptions = [
    { label: 'Ada saluran pembuangan air limbah ke tangka septik atau IPAL', value: 'Ada saluran pembuangan air limbah ke tangka septik atau IPAL' },
    { label: 'Ada saluran pembuangan air limbah ke sekolan/kali/sungai', value: 'Ada saluran pembuangan air limbah ke sekolan/kali/sungai' },
];

export const kemitraanPihakLuarOptions = [
    { label: 'Ada dengan pemerintah daerah', value: 'Ada dengan pemerintah daerah' },
    { label: 'Ada dengan puskesmas', value: 'Ada dengan puskesmas' },
    { label: 'Ada dengan perusahaan swasta', value: 'Ada dengan perusahaan swasta' },
    { label: 'Ada dengan perusahaan non pemerintah', value: 'Ada dengan perusahaan non pemerintah' },
    { label: 'Tidak Ada', value: 'Tidak Ada' },
];

export const satuanTransaksiOptions = [
    { label: 'Buah', value: 'Buah' },
    { label: 'Kotak', value: 'Kotak' },
    { label: 'Lusin', value: 'Lusin' },
    { label: 'Rim', value: 'Rim' },
    { label: 'Gross', value: 'Gross' },
    { label: 'Kodi', value: 'Kodi' },
    { label: 'Set', value: 'Set' },
    { label: 'Ruangan', value: 'Ruangan' },
    { label: 'Orang', value: 'Orang' },
    { label: 'Rupiah', value: 'Rupiah' },
];
export const jenisTransaksiOptions = [
    { label: 'Masuk', value: 'Masuk' },
    { label: 'Keluar', value: 'Keluar' },
];
export const kategoriSaranaPembelajaranOptions = [
    { label: 'Website', value: 'Website' },
    { label: 'Email', value: 'Email' },
    { label: 'Sarana', value: 'Sarana' },
    { label: 'Prasarana', value: 'Prasarana' },
];
export const statusBookingRuanganOptions = [
    { label: 'Draft', value: 'Draft' },
    { label: 'Diajukan', value: 'Diajukan' },
    { label: 'Disetujui', value: 'Disetujui' },
    { label: 'Ditolak', value: 'Ditolak' },
    { label: 'Dibatalkan', value: 'Dibatalkan' },
    { label: 'Selesai', value: 'Selesai' },
];

export const statusKetidakhadiranOptions = [
    { label: 'Draft', value: 'Draft' },
    { label: 'Diajukan', value: 'Diajukan' },
    { label: 'Disetujui', value: 'Disetujui' },
    { label: 'Ditolak', value: 'Ditolak' },
    { label: 'Selesai', value: 'Selesai' },
];
export const kategoriJalurPPDBOptions = [
    { label: 'Jalur Prestasi', value: 'Jalur Prestasi' },
    { label: 'Jalur Umum', value: 'Jalur Umum' },
];

export const referensiPPDBOptions = [
    { label: 'Sosial Media Resmi(Instagram, Facebook, Youtube, Twitter)', value: 'Sosial Media Resmi(Instagram, Facebook, Youtube, Twitter)' },
    { label: 'Website Man', value: 'Website Man' },
    { label: 'Poster', value: 'Poster' },
    { label: 'Brosur', value: 'Brosur' },
    { label: 'Banner', value: 'Banner' },
    { label: 'Iklan Medsos (Melalui iklan di medsos lain seperti Infogresik, dll)', value: 'Iklan Medsos (Melalui iklan di medsos lain seperti Infogresik, dll)' },
    { label: 'Sosialisasi MAN', value: 'Sosialisasi MAN' },
    { label: 'Teman/keluarga', value: 'Teman/keluarga' },
    { label: 'Alumni', value: 'Alumni' },
    { label: 'Bapak/Ibu Guru MAN', value: 'Bapak/Ibu Guru MAN' },
    { label: 'Lainnya', value: 'Lainnya' },
];

export const jenisBerkasPPDBOptions = [
    { label: 'Golden Ticket MAGIC MAN 1 Gresik', value: 'Golden Ticket MAGIC MAN 1 Gresik' },
    { label: 'Surat Rekomendasi Madrasah/Sekolah', value: 'Surat Rekomendasi Madrasah/Sekolah' },
    { label: 'Sertifikat Juara Lomba Akademik/Non Akademik', value: 'Sertifikat Juara Lomba Akademik/Non Akademik' },
    { label: 'Bukti Pernah Menjadi Ketua OSIS', value: 'Bukti Pernah Menjadi Ketua OSIS' },
    { label: "Sertifikat Hafalan Al-Qur'an", value: "Sertifikat Hafalan Al-Qur'an" },
    { label: "Legalisir Ijazah/SKL SMP/MTs", value: "Legalisir Ijazah/SKL SMP/MTs" },
];

export const statusPPDBOptions = [
    { label: "Belum Verifikasi", value: "Belum Verifikasi" },
    { label: "Disetujui", value: "Disetujui" },
    { label: "Ditolak", value: "Ditolak" },
    { label: "Lolos", value: "Lolos" },
    { label: "Tidak Lolos", value: "Tidak Lolos" },
    { label: "Terdaftar", value: "Terdaftar" },
    { label: "Mengundurkan Diri", value: "Mengundurkan Diri" },
];

export const waAccountStatusOptions = [
    { label: "Done", value: "Done" },
    { label: "Failed", value: "Failed" },
];
export const waLolosStatusOptions = [
    { label: "Done", value: "Done" },
    { label: "Failed", value: "Failed" },
];
export const dataStatusOptions = [
    { label: "Belum Lengkap", value: "Belum Lengkap" },
    { label: "Sudah lengkap", value: "Sudah lengkap" },
];
export const fileStatusOptions = [
    { label: "Belum Lengkap", value: "Belum Lengkap" },
    { label: "Sudah lengkap", value: "Sudah lengkap" },
];
export const jenisNotifOptions = [
    { label: 'Akun Login', value: 'Akun Login' },
    { label: 'Pengumuman Lolos', value: 'Pengumuman Lolos' },
];
export const statusNotifOptions = [
    { label: 'Failed', value: 'Failed' },
    { label: 'Done', value: 'Done' },
];

export const jenisAgendaOptions = [
    { label: 'Kalender Dinas Pendidikan', value: 'Kalender Dinas Pendidikan' },
    { label: 'Kalender MAN 1 Gresik', value: 'Kalender MAN 1 Gresik' },
];

export const statusAbsensiOptions = [
    { label: 'Masuk', value: 'Masuk' },
    { label: 'Libur', value: 'Libur' },
];
export const civitasAkademikaiOptions = [
    { label: 'Guru', value: 'Guru' },
    { label: 'Tendik', value: 'Tendik' },
    { label: 'Kelas 10', value: 'Kelas 10' },
    { label: 'Kelas 11', value: 'Kelas 11' },
    { label: 'Kelas 12', value: 'Kelas 12' },
];
export const jenisPenegakOptions = [
    { label: 'Penegak Bantara', value: 'Penegak Bantara' },
    { label: 'Penegak Laksana', value: 'Penegak Laksana' },
];
export const jenisPembayaranOptions = [
    { label: 'Hari', value: 'Hari' },
    { label: 'Minggu', value: 'Minggu' },
    { label: 'Bulan', value: 'Bulan' },
    { label: 'Tahun', value: 'Tahun' },
]

export const angsuranOptions = [
    { label: 'Ya', value: '1' },
    { label: 'Tidak', value: '0' },
]

export const tingkatanKelasOptions = [
    { label: '10 (X)', value: '10' },
    { label: '11 (XI)', value: '11' },
    { label: '12 (XII)', value: '12' },
]

export const jenisBiayaOptions = [
    { label: 'Debit', value: 'Debit' },
    { label: 'Kredit', value: 'Kredit' },
]
export const jenisExportTabunganOptions = [
    { label: 'Transaksi', value: 'transaksi' },
    { label: 'Saldo Akhir', value: 'saldo-akhir' },
]

export const paymentMethodOptions = [
    { label: 'Tunai', value: 'Tunai' },
    { label: 'Transfer', value: 'Transfer' },
    { label: 'Beasiswa', value: 'Beasiswa' },
]

export const presenceTypeOptions = [
    { label: 'Siswa', value: 'SISWA' },
    { label: 'Guru dan Tenaga Kependidikan', value: 'GTK' },
]

export const presenceActivitasOptions = [
    { label: 'Pembelajaran Siswa', value: 'PEMBELAJARAN SISWA' },
    { label: 'Pramuka', value: 'PRAMUKA' },
]