import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { _setAxios } from '../../lib/Helper';

async function fetchList(tipePerizinan) {
  if(!tipePerizinan){
    return [];
  }

  return await _setAxios("perizinan/jenis/table", "POST", {
    pagination: {
        current: 1,
        pageSize: 10000,
    },
    filtering: {
        tipe: tipePerizinan
    },
})
    .then(body => {
      let results = []
      body.data?.list?.forEach(row => {
        results.push({
          label: row.nama + " - " + row.inisial,
          value: row.id,
        })
      });

      return results
    })

}

const SelectJenisPerizinan = ({ ...props }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchList(props.tipePerizinan).then((newOptions) => {
      setOptions(newOptions);
    });
  }, [props.tipePerizinan]);

  return (
    <Select
      {...props}
      allowClear
      placeholder="Pilih jenis perizinan"
      style={{
        width: '100%',
      }}
      options={options}
    />
  );
};
export default SelectJenisPerizinan;
