import { _setAxios } from "../../lib/Helper"

export const settingService = () => {

    const getSettings = () => {
        return _setAxios(
            "konfigurasi/general-setting/table",
            "POST",
            {
                "pagination": {
                    "current": 1,
                    "pageSize": 10,
                },
                "search": "",
                "sorting": []
            })
            .then(body => {
                // return body.data?.data?
                return {
                    "status": true,
                    "status_code": 200,
                    "message": "Data berhasil ditampilkan",
                    "info": {
                        "page": 1,
                        "total": 19,
                        "total_filtered": 10
                    },
                    "list": [
                        {
                            "rownum": "1",
                            "id": "19",
                            "modul": "PPDB",
                            "user_role": "%",
                            "setting_name": "NoCSDaftarUlang",
                            "setting_val": "085645010434",
                            "url": null,
                            "created_at": "2024-08-05 14:30:00",
                            "created_by": "Developer",
                            "updated_at": null,
                            "updated_by": null
                        },
                        {
                            "rownum": "2",
                            "id": "18",
                            "modul": "PPDB",
                            "user_role": "%",
                            "setting_name": "NoCSDaftarUlang",
                            "setting_val": "082142442019",
                            "url": null,
                            "created_at": "2024-08-05 14:30:00",
                            "created_by": "Developer",
                            "updated_at": null,
                            "updated_by": null
                        },
                        {
                            "rownum": "3",
                            "id": "17",
                            "modul": "PPDB",
                            "user_role": "%",
                            "setting_name": "NoCSRegister",
                            "setting_val": "085888333359",
                            "url": null,
                            "created_at": "2024-08-05 14:30:00",
                            "created_by": "Developer",
                            "updated_at": null,
                            "updated_by": null
                        },
                        {
                            "rownum": "4",
                            "id": "16",
                            "modul": "PPDB",
                            "user_role": "%",
                            "setting_name": "Background",
                            "setting_val": "",
                            "url": "https://ppdb.dev.man1gresik.sch.id/static/media/img-login.79de4e97.png",
                            "created_at": "2024-08-05 14:30:00",
                            "created_by": "Developer",
                            "updated_at": null,
                            "updated_by": null
                        },
                        {
                            "rownum": "5",
                            "id": "15",
                            "modul": "PPDB",
                            "user_role": "%",
                            "setting_name": "Version",
                            "setting_val": "1.0",
                            "url": null,
                            "created_at": "2024-08-05 14:30:00",
                            "created_by": "Developer",
                            "updated_at": null,
                            "updated_by": null
                        },
                        {
                            "rownum": "6",
                            "id": "14",
                            "modul": "PPDB",
                            "user_role": "%",
                            "setting_name": "IsActive",
                            "setting_val": "1",
                            "url": null,
                            "created_at": "2024-08-05 14:30:00",
                            "created_by": "Developer",
                            "updated_at": null,
                            "updated_by": null
                        },
                        {
                            "rownum": "7",
                            "id": "13",
                            "modul": "PPDB",
                            "user_role": "%",
                            "setting_name": "IsMaintenance",
                            "setting_val": "0",
                            "url": null,
                            "created_at": "2024-08-05 14:30:00",
                            "created_by": "Developer",
                            "updated_at": null,
                            "updated_by": null
                        },
                        {
                            "rownum": "8",
                            "id": "12",
                            "modul": "Aplikasi Eksternal",
                            "user_role": "%",
                            "setting_name": "IsMaintenance",
                            "setting_val": "0",
                            "url": null,
                            "created_at": "2024-08-05 14:30:00",
                            "created_by": "Developer",
                            "updated_at": null,
                            "updated_by": null
                        },
                        {
                            "rownum": "9",
                            "id": "11",
                            "modul": "Aplikasi Internal",
                            "user_role": "%",
                            "setting_name": "IsMaintenance",
                            "setting_val": "0",
                            "url": null,
                            "created_at": "2024-08-05 14:30:00",
                            "created_by": "Developer",
                            "updated_at": null,
                            "updated_by": null
                        },
                        {
                            "rownum": "10",
                            "id": "10",
                            "modul": "Rombel Dropdown",
                            "user_role": "Guru;Tendik;Wali Kelas;Guru BK",
                            "setting_name": "DataAccess",
                            "setting_val": "",
                            "url": null,
                            "created_at": "2024-08-05 14:30:00",
                            "created_by": "Developer",
                            "updated_at": null,
                            "updated_by": null
                        }
                    ],
                    "message_original ": "Data berhasil ditampilkan",
                    "data": []
                }
            })
    }

    return {
        getSettings
    }

}