import React from 'react';

import { Select } from 'antd';
import { presenceActivitasOptions } from '../../data/options';

const SelectPresenceActivitas = ({ ...props }) => {
  return (
    <Select
      {...props}
      placeholder="Pilih aktivitas"
      style={{
        width: '100%',
      }}
      options={presenceActivitasOptions}
    />
  );
};
export default SelectPresenceActivitas;
