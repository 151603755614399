import React, { useEffect, useState } from 'react';
import { _setAxios } from '../../../lib/Helper';
import { Autocomplete } from '@mui/material';
import { Skeleton } from 'antd';

async function fetchList() {

  return await _setAxios("setting/dropdown/modul/50")
    .then(body => {
      let results = []
      body.data?.data?.forEach(row => {
        results.push({
          label: row.list,
          value: row.list,
        })
      });

      return results
    })
}

const SelectJarakLokasiTertentu = ({ ...props }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchList().then((newOptions) => {
      setOptions(newOptions);
    });
  }, []);

  if (!options.length) return <Skeleton.Button active block />

  return (
    <Autocomplete
      {...props}
      value={options.find(option => option.value === props.value)}
      options={options}
      noOptionsText="Tidak ada data"
    />
  );
};
export default SelectJarakLokasiTertentu;
