import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { _setAxios } from '../../lib/Helper';

async function fetchList() {

  return await _setAxios("setting/dropdown/modul/1")
    .then(body => {
      let results = []
      body.data?.data?.forEach(row => {
        results.push({
          label: row.list,
          value: row.list,
        })
      });

      return results
    })

}

const SelectJenisDocLembaga = ({ ...props }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchList().then((newOptions) => {
      setOptions(newOptions);
    });
  }, []);

  return (
    <Select
      {...props}
      allowClear
      placeholder="Pilih jenis dokumen"
      style={{
        width: '100%',
      }}
      options={options}
    />
  );
};
export default SelectJenisDocLembaga;
