import React, { useEffect, useState } from 'react';
import { Flex, Select } from 'antd';
import { _setAxios } from '../../lib/Helper';

async function fetchList(tahunAjaranId) {
  if (!tahunAjaranId) {
    return []
  }

  return await _setAxios("tahun-ajaran/semester/" + tahunAjaranId, "GET")
    .then(body => {
      let results = []
      body.data?.data?.forEach(row => {

        results.push({
          label: <Flex><div style={{ width: '100px' }}>{row.tipe_semester}</div> <div>{row.is_active === "1" && row.is_deleted === "0" ? "Aktif" : "Tidak aktif"}</div></Flex>,
          value: row.id,
        })
      });

      return results
    })
}

const SelectSemester = ({ ...props }) => {
  const [options, setOptions] = useState([]);
  const tahunAjaranId = props.tahunAjaranId || props.tahunAjaranId === 0 ? props.tahunAjaranId : null

  useEffect(() => {
    fetchList(tahunAjaranId).then((newOptions) => {
      setOptions(newOptions);
    });
  }, [tahunAjaranId]);

  return (
    <Select
      {...props}
      placeholder="Pilih semester"
      style={{
        width: '100%',
      }}
      options={options}
    />
  );
};
export default SelectSemester;
