import React from 'react';
import { connect } from 'react-redux'
import { _success, _validationFormMsg, _setAxios, _logout } from '../../lib/Helper';
import validate from 'validate.js';

import { Layout, Modal, Button, Menu, Row, Col, List, Avatar, Dropdown, Drawer, Divider, Space, Typography, Popover } from 'antd';
import { MenuUnfoldOutlined, DownCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { noImage } from '../../data/image';
import { BrowserView, MobileView } from 'react-device-detect';
import logoMan from '../../assets/images/logo-man.png';
import "./style.css"

const { Header } = Layout;
const { Title, Text } = Typography;

const schema = {
  password_old: {
    presence: { allowEmpty: false, message: 'wajib diisi' },
    length: {
      maximum: 100
    }
  },
  password_new: {
    presence: { allowEmpty: false, message: 'wajib diisi' },
    length: {
      maximum: 100
    }
  },
  password_new_confirm: {
    presence: { allowEmpty: false, message: 'wajib diisi' },
    length: {
      maximum: 100
    },
    equality: {
      attribute: "password_new",
      message: "wajib sama dengan password baru",
      comparator: function (v1, v2) {
        return JSON.stringify(v1) === JSON.stringify(v2);
      }
    },
  }
};

class HeaderLayout extends React.Component {
  state = {
    collapsed: false,
    openKey: 0,
    selectedKey: 0,
    updatePassword: {
      visible: false,
      loading: false
    },
    values: {},
    errors: {},
    drawer: {
      visible: false
    },
  };

  handleLogOut() {
    Modal.confirm({
      title: 'Keluar',
      width: 270,
      icon: <ExclamationCircleOutlined />,
      content: <>Apakah Anda Yakin ?</>,
      cancelText: 'Batal',
      okText: 'Keluar',
      onOk: () => {
        _logout()
      },
      onCancel: () => {
        console.log("No action to process")
      },
    });
  }

  // UPDATE PASSWORD START ------------------------------------------

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setForm(name, value)
  };

  setForm = (name, value, row = {}) => {
    const { values } = this.state;
    values[name] = value;
    const errors = validate(values, schema);
    this.setState({
      values: values,
      errors: errors || {},
    });
  }

  hasError = field => {
    return this.state.errors[field] ? true : false;
  }
  showModalUpdatePassword = (row) => {
    this.setState({
      updatePassword: {
        visible: true,
        loading: false
      },
    });
  };

  hideModalUpdatePassword = () => {
    this.setState({
      updatePassword: {
        visible: false,
        loading: false
      }
    });
  };

  updatePasswordSubmit = (e) => {
    e.preventDefault();
    const { values } = this.state;
    const errors = validate(values, schema);
    if (errors) {
      return _validationFormMsg(errors)
    }

    this.setState({
      updatePassword: {
        ...this.state.updatePassword,
        loading: true
      }
    });
    _setAxios("user/ubah-password/" + this.props.profile?.id, "PUT", {
      password_old: values.password_old,
      password_new: values.password_new,
      password_confirm: values.password_new_confirm,
    }).then(resp => {
      if (resp.status === true) {
        this.hideModalUpdatePassword()
        _success('topRight', 'Success', resp.data.message)
      } else {
        this.setState({
          updatePassword: {
            ...this.state.updatePassword,
            loading: false
          }
        });
      }
    })

  };
  // UPDATE PASSWORD END ------------------------------------------


  onMenuCollapse = () => {
    this.props.setMenuCollapse(!this.props.menuCollapse)
  }

  showDrawer = () => {
    this.setState({
      drawer: {
        visible: true,
      },
    });
  };

  closeDrawer = () => {
    this.setState({
      drawer: {
        visible: false,
      },
    });
  };

  closeDrawerParent = (path) => {
    if (path === "" || path === "/" || path === "#") {
      this.closeDrawer()
    }
  };

  getItem(name, label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
      name,
    };
  }

  render() {
    const { values, errors } = this.state
    const { privilege } = this.props
    const { tahun_ajaran, profile } = privilege

    const profileContent = (
      <List
        className='message-content'
        itemLayout="horizontal"
        dataSource={[""]}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              description={<>
                <Menu>
                  <Menu.Item onClick={() => window.location.href = "/setting"}>
                    Setting
                  </Menu.Item>
                  <Menu.Item onClick={this.showModalUpdatePassword}>
                    Ganti Password
                  </Menu.Item>
                  <Menu.Item onClick={this.handleLogOut}>
                    Logout
                  </Menu.Item>
                </Menu>
              </>}
            />
          </List.Item>
        )}
      />
    );

    const modalUpdatePassword = <Modal
      title={`Ganti Password Akun - ` + profile?.name}
      onCancel={this.hideModalUpdatePassword}
      visible={this.state.updatePassword.visible}
      footer={<>
        <Button onClick={this.hideModalUpdatePassword}>Batal</Button>
        <Button type="primary" htmlType="submit" onClick={this.updatePasswordSubmit} loading={this.state.updatePassword.loading}>Simpan</Button>
      </>}

    >
      <form autoComplete="off" onSubmit={this.updatePasswordSubmit}>
        <Row gutter={[24, 24]}>
          <Col xs={24}>
            <TextField
              fullWidth
              label="Password Lama"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              name="password_old"
              type="password"
              onChange={this.handleChange}
              value={values.password_old || ''}
              error={this.hasError('password_old')}
              helperText={
                this.hasError('password_old') ? errors.password_old[0] : null
              }
            />
          </Col>
          <Col xs={24}>
            <TextField
              fullWidth
              label="Password"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              name="password_new"
              type="password"
              onChange={this.handleChange}
              value={values.password_new || ''}
              error={this.hasError('password_new')}
              helperText={
                this.hasError('password_new') ? errors.password_new[0] : null
              }
            />
          </Col>
          <Col xs={24}>
            <TextField
              fullWidth
              label="Konfirmasi Password"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              name="password_new_confirm"
              type="password"
              onChange={this.handleChange}
              value={values.password_new_confirm || ''}
              error={this.hasError('password_new_confirm')}
              helperText={
                this.hasError('password_new_confirm') ? errors.password_new_confirm[0] : null
              }
            />
          </Col>
        </Row>
      </form>
    </Modal>

    const menu = this.props.menu
    const items = []
    const itemsMenu = []
    for (let i = 0; i < menu.length; i++) {
      const subItems = []
      if (menu[i].children) {
        if (menu[i].children.length > 0) {
          for (let j = 0; j < menu[i].children.length; j++) {
            const subMenu = menu[i].children[j]
            if (subMenu.name.includes("HiddenMenu")) {
              continue;
            }
            if (subMenu.path.includes("?tab=")) {
              subMenu.path = subMenu.path.split("?tab=")[0]
            }
            subItems.push(this.getItem(subMenu.name, <Link to={subMenu.path} onClick={this.closeDrawer}>{subMenu.name}</Link>, (subMenu.path ? subMenu.path : i), <span className="icon-sidebar icon-circle" style={{ fontSize: "60%" }}></span>))
          }
        }
      }

      let linkPath = ""
      if (menu[i].path.includes("drive.google.com")) {
        linkPath = <a href={menu[i].path} target="_blank" rel="noopener noreferrer" onClick={subItems.length === 0 ? this.closeDrawer : null}>{menu[i].name}</a>
      } else {
        linkPath = <Link to={menu[i].path} onClick={subItems.length === 0 ? this.closeDrawer : null}>{menu[i].name}</Link>
      }

      // if (menu[i].children && !excludeWidgetPath.includes(menu[i].path)) {
      //   items.push(this.getItem(menu[i].name, linkPath, menu[i].path ? menu[i].path : i, menu[i].icon ? <span className={`icon-sidebar ${menu[i].icon}`}></span> : <span className="icon-sidebar icon-dashboard"></span>, subItems.length > 0 ? subItems : null))
      // }
      // if (excludeWidgetPath.includes(menu[i].path) || menu[i].path.includes("drive.google.com")) {
      //   itemsMenu.push(this.getItem(menu[i].name, linkPath, menu[i].path ? menu[i].path : i, menu[i].icon ? <span className={`icon-sidebar ${menu[i].icon}`}></span> : <span className="icon-sidebar icon-dashboard"></span>, subItems.length > 0 ? subItems : null))
      // }
      itemsMenu.push(this.getItem(menu[i].name, linkPath, menu[i].path ? menu[i].path : i, menu[i].icon ? <span className={`icon-sidebar ${menu[i].icon}`}></span> : <span className="icon-sidebar icon-dashboard"></span>, subItems.length > 0 ? subItems : null))
    }


    let styleProfile = {
      margin: "-54px auto 0px",
      width: "fit-content"
    }
    let styleProfileImage = {
      backgroundImage: `url("` + logoMan + `")`,
      backgroundColor: "rgb(255, 255, 255)",
      padding: "8px",
      borderRadius: "100%",
      width: "80px",
      overflow: "hidden",
      height: "80px",
      backgroundSize: "56px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      border: "3px solid rgb(224 224 224 / 50%)"
    }

    return (
      <>
        <Header className="site-layout-background" style={{
          borderRadius: 0
        }}>
          <Row gutter={[16, 16]}>
            <Col xs={12}>
              <BrowserView>
                <Button type="primary" icon={<MenuUnfoldOutlined />}
                  onClick={this.onMenuCollapse}
                />
              </BrowserView>
              <MobileView>
                <Button type="primary" icon={<MenuUnfoldOutlined />} onClick={this.showDrawer} />
              </MobileView>
            </Col>
            <Col xs={12}>
              <div className='nav-profile'>
                <Dropdown overlay={profileContent} trigger={['click']} placement="bottomRight">
                  <div>
                    <div className="navbar-grid-container">
                      <div className="navbar-icon"><Avatar src={noImage} /></div>
                      <div className="navbar-title1">Masuk sebagai</div>
                      <div className="navbar-title2">{profile?.roles}</div>
                      <div className="navbar-icon2"><DownCircleOutlined /></div>
                    </div>
                  </div>

                </Dropdown>
              </div>
            </Col>
          </Row>
        </Header>

        <Drawer
          title={false}
          headerStyle={{
            display: "none"
          }}
          bodyStyle={{
            padding: 0
          }}
          placement="left"
          onClose={this.closeDrawer}
          open={this.state.drawer.visible}
          width={320}>
          <Row gutter={[16, 16]}>
            {
              this.props.menuCollapse ? "" :
                <Col xs={24}>
                  <div className='sidebar-profile'>
                    <div className='sidebar-grid-container'>
                    </div>
                  </div>
                </Col>
            }
            <Col xs={24}>
              <div style={styleProfile}>
                <div style={styleProfileImage}>
                </div>
              </div>
            </Col>
            <Col xs={24} style={{
              textAlign: "center"
            }}>
              <div style={this.props.menuCollapse ? {
                fontSize: '50%'
              } : {
                fontWeight: "bold"
              }}>{profile?.name}</div>
              <div style={this.props.menuCollapse ? {
                fontSize: '50%',
                color: "#8C8C8C"
              } : {
                color: "#8C8C8C"
              }}>{profile?.roles}</div>
              <Divider style={{ marginBottom: 8 }} />
              <Space>
                <Title level={5}><Text type="secondary">Semester {tahun_ajaran?.tipe_semester}</Text> | {tahun_ajaran?.tahun_ajaran}</Title>
              </Space>
              <Divider style={{ marginTop: 4 }} />
            </Col>
          </Row>
          <div className='container-widget'>
            {
              items.map((item) => {
                return <Popover
                  className='item-widget'
                  placement='bottomRight'
                  title={item.name}
                  content={<div>
                    {
                      item.children.map((subItem) => {
                        return <div className='link-widget-content'><Link to={subItem.key} title={subItem.name} className='link-widget' >{subItem.icon} {subItem.name}</Link></div>
                      })
                    }
                  </div>}>
                  <Button title={item.name} type="primary" icon={item.icon} />
                </Popover>
              })
            }
          </div>
          <Menu
            mode="inline"
            items={itemsMenu} />
        </Drawer>

        {modalUpdatePassword}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
    menu: state.menu,
    menuCollapse: state.menuCollapse
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setMenuCollapse: (flag) => {
      dispatch({ type: "MENU", menuCollapse: flag })
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(HeaderLayout);
