import React, { useCallback, useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from "react-leaflet";
import { Button, Spin, Input, List } from "antd";
import "leaflet/dist/leaflet.css";
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import 'leaflet-control-geocoder'; // Import the Geocoder
import L from "leaflet";

const geocoder = L.Control.Geocoder.nominatim({
  countrycodes: 'ID', // Membatasi hasil hanya untuk Indonesia
  bounded: 1, // Membatasi pencarian hanya dalam viewbox
  bbox: [5.88969, 95.3161, -10.3606, 141.0194]
});
// Set manual icon marker untuk mengatasi masalah
const customIcon = new L.Icon({
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  shadowSize: [41, 41],
});

const SearchMap = ({ setMarker, setSearchResults }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);

  const handleSearch = useCallback((address) => {
    geocoder.geocode(address, (results) => {
      if (results && results.length) {
        setSearchResults(results);
      } else {
        setSearchResults([]);
      }
    });
  }, [setSearchResults]);


  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 300); // 300ms delay

    return () => {
      clearTimeout(handler); // Clear timeout jika ada input baru
    };
  }, [searchTerm]);

  useEffect(() => {
    if (debouncedTerm) {
      handleSearch(debouncedTerm);
    }
  }, [debouncedTerm, handleSearch]);

  return (
    <Input
      type="text"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      onClick={(e) => {
        setSearchTerm(e.target.value)
      }}
      placeholder="Ketik untuk mencari..."
      style={{ position: 'absolute', top: '10px', left: '10px', zIndex: 999, width: '89%', margin: 8 }}
    />
  );
};


const OnRenderInitialLocation = ({ marker, setAddress }) => {
  const map = useMapEvents({});

  useEffect(() => {
    if (marker) {
      geocoder.reverse(
        { lat: marker.lat, lng: marker.lng },
        map.options.crs.scale(map.getZoom()),
        (results) => {
          const address = results[0]?.name || "Address not found";
          setAddress(address);
        }
      );
    }
  }, []);

  return
};

const OnRenderCurrentButton = ({ setPosition, setMarker, setLoading, setAddress }) => {
  const map = useMapEvents({});

  const handleCurrentLocation = () => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          setPosition([latitude, longitude]);
          setMarker([latitude, longitude]);
          map.setView([latitude, longitude], 13);
          setLoading(false);
          geocoder.reverse(
            { lat: latitude, lng: longitude },
            map.options.crs.scale(map.getZoom()),
            (results) => {
              const address = results[0]?.name || "Address not found";
              setAddress(address);
            }
          );
        },
        (error) => {
          console.error("Error getting location: ", error);
          setLoading(false);
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
      setLoading(false);
    }
  };

  return (
    <Button type="primary" onClick={handleCurrentLocation} style={{ position: "absolute", bottom: "20px", right: "16px", zIndex: 1000 }}>
      Current My Location
    </Button>
  );
};

const AddSingleMarkerOnClick = ({ setMarker, setAddress, setPosition }) => {
  const map = useMapEvents({
    click(e) {
      if (!e.originalEvent.target.classList.contains("leaflet-container")) {
        return;
      }
      const { lat, lng } = e.latlng;
      setMarker([lat, lng]);
      setPosition([lat, lng]);
      map.setView([lat, lng], 13);
      geocoder.reverse(
        { lat, lng },
        map.options.crs.scale(map.getZoom()),
        (results) => {
          const address = results[0]?.name || "Address not found";
          setAddress(address);
        }
      );

    },
  });

  return null;
};


const ZoomControls = () => {
  const map = useMap();

  const zoomIn = () => {
    map.zoomIn();
  };

  const zoomOut = () => {
    map.zoomOut();
  };

  return (
    <div style={{ position: "absolute", bottom: "20px", left: "16px", zIndex: 1000 }}>
      <Button onClick={zoomIn}>+</Button>
      <Button onClick={zoomOut} style={{ marginLeft: "5px" }}>-</Button>
    </div>
  );
};

const OnRenderSelectAddress = ({ setAddress, setPosition, setMarker, setLoading }) => {
  const map = useMapEvents({});
  const [searchResults, setSearchResults] = useState([]);

  const handleSelectAddress = (result, setAddress, setSearchResults, setPosition, setMarker) => {
    const { center } = result;
    const { lat, lng } = center;

    setAddress(result.name);
    setSearchResults([]);
    setPosition([lat, lng]);
    setMarker([lat, lng]);

    setLoading(true);
    setTimeout(() => {
      map.setView([lat, lng], 13);
      setLoading(false);
    }, 1000);
  };

  return (
    <div onMouseLeave={() => setSearchResults([])}>
      <SearchMap setMarker={setMarker} setSearchResults={setSearchResults} />
      {searchResults.length > 0 && (
        <List
          bordered
          dataSource={searchResults}
          renderItem={(result) => (
            <List.Item
              onClick={() => handleSelectAddress(result, setAddress, setSearchResults, setPosition, setMarker)}
              style={{ cursor: 'pointer' }}>
              {result.name}
            </List.Item>
          )}
          style={{ position: 'absolute', top: '39px', left: '10px', zIndex: 1000, background: 'white', margin: 8, width: '89%', overflow: 'auto' }}
        />
      )}
    </div>
  );
};

const MapComponent = ({ marker, setMarker, setAddress }) => {
  const [position, setPosition] = useState([marker?.lat || 0, marker?.lng || 0]);
  const [loading, setLoading] = useState(false);

  return (
    <div style={{ position: "relative" }}>
      {loading && <Spin style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 1000 }} />}
      <MapContainer center={position} zoom={13} style={{ height: "300px", width: "100%" }} zoomControl={false}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {marker && <Marker position={marker} icon={customIcon} />}
        <ZoomControls />
        <OnRenderInitialLocation marker={marker} setAddress={setAddress} />
        <OnRenderCurrentButton setPosition={setPosition} setMarker={setMarker} setLoading={setLoading} setAddress={setAddress} />
        <AddSingleMarkerOnClick setMarker={setMarker} setAddress={setAddress} setPosition={setPosition} />
        <OnRenderSelectAddress setAddress={setAddress} setSearchResults={[]} setPosition={setPosition} setMarker={setMarker} setLoading={setLoading} />
      </MapContainer>
    </div>
  );
};

export default MapComponent;
