// useForm.js
import { useEffect, useState } from 'react';
import { _getBase64Upload, _getDistance, _setAxios, _success } from 'lib/Helper';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import config from 'Config';
import { message } from 'antd';
import { dateFormat } from 'constant/constant_format';
import { pembelajaranKelas } from 'constant/constant_string';

dayjs.extend(customParseFormat);
const dateNow = dayjs().format(dateFormat);

const useForm = (presensiSetting, data, fetchTable, hideModal) => {
    const [previewMasuk, setPreviewMasuk] = useState(data.foto_masuk ? data.main_path + data.foto_masuk : null);
    const [previewPulang, setPreviewPulang] = useState(data.foto_pulang ? data.main_path + data.foto_pulang : null);

    const [values, setValues] = useState({
        ...data,
        is_active: false,
        is_deleted: false,
        tgl: data.tgl ? dayjs(data.tgl) : dayjs(dateNow),
        location: {
            lat: config?.location?.lat,
            lng: config?.location?.lng
        },
    });
    const [loading, setLoading] = useState(false);
    const [loadingContent, setLoadingContent] = useState(true);

    useEffect(() => {
        setValues(prev => ({
            ...prev,
            is_active: data.is_active === "1" ? true : false,
            is_deleted: data.is_deleted === "0" ? true : false,
        }));
        setLoadingContent(false);
    }, [data.is_active, data.is_deleted]);

    const handleChangeSetValue = (name, value) => {
        setValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    const handleSubmit = (val) => {
        const times = val.waktu
        if (times.length < 2) {
            message.error("Jam masuk dan jam pulang harus diisi");
            return;
        }

        if (times[0] !== null && times[1] !== null && times[0] >= times[1]) {
            message.error("Jam masuk harus lebih kecil dari jam pulang");
            return;
        }

        if (times[0] < new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })) {
            message.error("Jam masuk tidak boleh kurang dari sekarang");
            return;
        }

        if (times[1] < new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })) {
            message.error("Jam pulang tidak boleh kurang dari sekarang");
            return;
        }

        const radiusMasuk = _getDistance([presensiSetting?.lat, presensiSetting?.long], [val?.location?.lat, val?.location?.lng])
        const radiusPulang = _getDistance([presensiSetting?.lat, presensiSetting?.long], [val?.location?.lat, val?.location?.lng])
        const fotoMasuk = previewMasuk ? _getBase64Upload(previewMasuk) : null
        const fotoPulang = previewPulang ? _getBase64Upload(previewPulang) : null;


        const params = {
            tahun_ajaran_id: val?.tahun_ajaran_id,
            semester_id: val?.semester_id,
            rombel_id: val?.rombel_id,
            siswa_id: val?.siswa_id,
            tgl: dayjs(val?.tgl).format(dateFormat),
            waktu_masuk: times[0] && new Date(times[0]).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }),
            waktu_pulang: times[1] && new Date(times[1]).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }),
            lat_masuk: val?.location?.lat,
            long_masuk: val?.location?.lng,
            lat_pulang: val?.location?.lat,
            long_pulang: val?.location?.lng,
            radius_masuk: radiusMasuk,
            radius_pulang: radiusPulang,
            foto_masuk: fotoMasuk,
            foto_pulang: fotoPulang,
            aktivitas: pembelajaranKelas
        };

        let endpoint = "presensi/siswa/kehadiran";
        let method = "POST";

        if (data?.id) { 
            endpoint = `${endpoint}/${data?.id}`;
            method = "PUT";
        }

        setLoading(true);
        _setAxios(endpoint, method, params).then(resp => {
            console.log("resp", resp.status);
            if (resp.status) {
                _success('topRight', 'Success', resp.data.message)
                fetchTable(true);
                hideModal();
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        });
    };

    return {
        values,
        loading,
        loadingContent,
        previewMasuk,
        previewPulang,
        setPreviewMasuk,
        setPreviewPulang,
        handleChangeSetValue,
        handleSubmit,
    };
};

export default useForm;
