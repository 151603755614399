import { useCallback, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { getParamTable, getParamTempTableFiltering, _setAxios } from 'lib/Helper';
import moment from 'moment';

const initialTableState = () => ({
    loading_first: false,
    loading: false,
    data: [],
    search: "",
    pagination: {
        current: 1,
        pageSize: 10,
        total: null,
    },
    sorting: [],
    filtering: {
        tgl_awal: moment().format("YYYY-MM-DD"),
        tgl_akhir: moment().format("YYYY-MM-DD"),
    },
});

const useKetidakhadiranSiswa = (tahunAjaran, semester) => {
    const [table, setTable] = useState({
        ...initialTableState(),
        filtering: {
            ...initialTableState().filtering,
            tahun_ajaran_id: tahunAjaran?.id,
            semester_id: semester?.id
        },
    });
    const [filter, setFilterState] = useState({
        values: {
            ...table.filtering
        }, visible: false
    });
    const [deleteState, setDeleteState] = useState({ visible: false, data: {} });

    const fetchTable = (isNew = false) => {
        const params = getParamTable("default", table);
        fetchData(params, isNew);
    };

    const fetchData = useCallback(async (params = {}, isNew = false) => {
        updateTableLoadingState(isNew);
        params.pagination.current = isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current;

        const resp = await _setAxios("presensi/gtk/ketidakhadiran/table", "POST", params);
        if (resp.status) {
            setTimeout(() => {
                updateTableData(resp.data, params.pagination.current, isNew);
            }, 0);
        } else {
            resetTableLoadingState();
        }
    }, []);

    const updateTableLoadingState = (isNew) => {
        setTable(prev => ({
            ...prev,
            loading_first: isMobile ? isNew : false,
            loading: true
        }));
    };

    const resetTableLoadingState = () => {
        setTable(prev => ({ ...prev, loading_first: false, loading: false }));
    };

    const updateTableData = (data, current, isNew) => {
        const updatedData = isNew ? [] : table.data;
        const newCurrent = isMobile ? current + 1 : current;

        setTable(prev => ({
            ...prev,
            loading_first: false,
            loading: false,
            data: isMobile ? [...updatedData, ...data?.list] : data?.list,
            pagination: {
                ...prev.pagination,
                current: newCurrent,
                total: data.info.total,
            },
        }));
    };

    const setTempTableFilter = (name, value) => {
        setFilterState(prev => ({
            ...prev,
            values: { ...prev.values, [name]: value }
        }));
        setTable(prev => ({ ...prev, filtering: { ...prev.filtering, [name]: value } }));
    };

    const setTableFilterFromTemp = () => {
        const params = getParamTempTableFiltering(table, filter.values);
        fetchData(params, isMobile);
        modalFilter(false);
    };

    const modalFilter = (visible = false) => {
        setFilterState(prev => ({ ...prev, visible }));
    };

    const modalDelete = (visible = false, data = {}) => {
        setDeleteState(prev => ({ ...prev, visible, data }));
    };

    return {
        table,
        filter,
        deleteState,
        fetchTable,
        fetchData,
        setTempTableFilter,
        setTableFilterFromTemp,
        modalFilter,
        modalDelete,
    };
};

export default useKetidakhadiranSiswa;
