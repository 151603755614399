import React, { useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { Select, Spin } from 'antd';
import { _setAxios } from '../../lib/Helper';
function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  useEffect(() => {
    fetchList().then((newOptions) => {
      setOptions(newOptions);
    });
  }, []);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      showSearch
      allowClear
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      placeholder="Pilih siswa"
      {...props}
      options={options}
    />
  );
}

async function fetchList(keyword) {
  return await _setAxios("siswa", "POST", {
    search: keyword ? keyword : null,
  })
    .then(body => {
      let results = []
      body.data?.list?.forEach(row => {
        results.push({
          label: row.nama,
          value: row.id,
          data: row
        })
      });

      return results
    })
}

const SelectSiswa = ({ ...props }) => {
  return (
    <DebounceSelect
      {...props}
      fetchOptions={fetchList}
      style={props.style ? props.style : {
        width: '100%',
      }}
    />
  );
};
export default SelectSiswa;
