import React, { useState } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Row, Col, BackTop, Breadcrumb, Button } from 'antd';
import { DoubleRightOutlined, PlusOutlined } from '@ant-design/icons';
import {TableList} from 'components/list';
import { isAuth, getParamTable, getParamTempTableFiltering, _setAxios } from '../../../../lib/Helper';
import AuthRedirect from '../../../../components/AuthRedirect';
import Form from '../modal/Form';
import Filter from '../modal/Filter';
import ModalDelete from '../modal/Delete';
import SearchTable from '../../../../components/search-table/SearchTable';
import { PageHeader } from 'components/header';
import { SortingTableWithFilter } from 'components/sorting-table';
import moment from 'moment';
import columns from './Columns';

const sortFields = [{
  value: "id",
  label: "Tanggal Pembuatan",
}];

const initialTableState = () => ({
  loading_first: false,
  loading: false,
  data: [],
  search: "",
  pagination: {
    current: 1,
    pageSize: 10,
    total: null,
  },
  sorting: [],
  filtering: {},
});

const KonfigurasiPresensi = ({ privilege }) => {
  const [table, setTable] = useState(initialTableState());
  const [form, setFormState] = useState({ data: {}, visible: false });
  const [filter, setFilterState] = useState({ values: {
    ...table.filtering
  }, visible: false });
  const [deleteState, setDeleteState] = useState({ data: {}, visible: false });

  const fetchTable = (isNew = false) => {
    const params = getParamTable("default", table);
    fetchData(params, isNew);
  };

  const fetchData = async (params = {}, isNew = false) => {
    updateTableLoadingState(isNew);

    params.pagination.current = isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current;

    const resp = await _setAxios("presensi/setting/table", "POST", params);
    if (resp.status) {
      setTimeout(() => {
         updateTableData(resp.data, params.pagination.current, isNew);
      }, 0);
    } else {
      resetTableLoadingState();
    }
  };

  const updateTableLoadingState = (isNew) => {
    setTable(prev => ({
      ...prev,
      loading_first: isMobile ? isNew : false,
      loading: true
    }));
  };

  const resetTableLoadingState = () => {
    setTable(prev => ({ ...prev, loading_first: false, loading: false }));
  };

  const updateTableData = (data, current, isNew) => {
    const updatedData = isNew ? [] : table.data;
    const newCurrent = isMobile ? current + 1 : current;

    setTable(prev => ({
      ...prev,
      loading_first: false,
      loading: false,
      data: isMobile ? [...updatedData, ...data?.list] : data?.list,
      pagination: {
        ...prev.pagination,
        current: newCurrent,
        total: data.info.total,
      },
    }));
  };

  const setTempTableFilter = (name, value) => {
    setFilterState(prev => ({
      ...prev,
      values: { ...prev.values, [name]: value }
    }));
  };

  const setTableFilterFromTemp = () => {
    const params = getParamTempTableFiltering(table, filter.values);
    fetchData(params, isMobile);
    modalFilter(false);
  };

  const modalFilter = (visible = false) => {
    setFilterState(prev => ({ ...prev, visible }));
  };

  const modalForm = (visible = false, data = {}) => {
    if (data.id) {
      data.radius_active = data.radius_active === "1" ? true : false
      data.jam = [data?.jam_masuk && moment(data?.jam_masuk, 'HH:mm:ss'), data?.jam_pulang && moment(data?.jam_pulang, 'HH:mm:ss')]
      
    }
    setFormState({ visible, data });
  };

  const modalDelete = (visible = false, data = {}) => {
    setDeleteState({ visible, data });
  };


  const access = privilege.access["/configuration/presensi"];
  if (!isAuth(privilege) || access === undefined) {
    return <AuthRedirect />;
  }
  const action = access.action;

  return (
    <>
      <PageHeader
        style={{ padding: 0 }}
        onBack={() => window.history.back()}
        subTitle={
          <Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
            <Breadcrumb.Item>Konfigurasi</Breadcrumb.Item>
            <Breadcrumb.Item>Presensi</Breadcrumb.Item>
          </Breadcrumb>
        }
        extra={[
          action.create ? <Button type='primary' onClick={() => modalForm(true)}><PlusOutlined /> Tambah</Button> : null,
        ]}
      />
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <PageHeader className="site-page-header" title="Presensi" subTitle="Data Konfigurasi Presensi" />
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={18} md={19}>
            <SearchTable table={table} fetch={fetchData} />
          </Col>
          <Col xs={24} sm={6} md={5}>
            <SortingTableWithFilter
              table={table}
              fetch={fetchData}
              modalFilter={modalFilter}
              sortFields={sortFields}
            />
          </Col>
          <Col xs={24}>
            <TableList
              state={{ table, filter }}
              fetch={fetchData}
              fetchTable={fetchTable}
              columns={columns(action, modalForm, modalDelete)}
            />
          </Col>
        </Row>
      </div>
      {form.visible && (
        <Form
          data={form.data}
          visible={form.visible}
          hideModal={() => modalForm(false)}
          fetchTable={fetchTable}
        />
      )}
      {deleteState.visible && (
        <ModalDelete
          data={deleteState.data}
          visible={deleteState.visible}
          hideModal={() => modalDelete(false)}
          fetchTable={fetchTable}
        />
      )}
      {filter.visible && (
        <Filter
          visible={filter.visible}
          hideModal={() => modalFilter(false)}
          setTempTableFilter={setTempTableFilter}
          setTableFilterFromTemp={setTableFilterFromTemp}
          values={filter.values}
        />
      )}
      <BackTop />
    </>
  );
};

const mapStateToProps = (state) => ({
  privilege: state.privilege,
});

export default connect(mapStateToProps)(KonfigurasiPresensi);