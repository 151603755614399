import React from 'react';
import { Modal, Button, Form, Row, Col, Select, message } from 'antd';
import { jenisAgendaOptions, statusAbsensiOptions } from '../../../../data/options';
import { SelectPresenceActivitas } from 'components/select';
import { _getDate } from 'lib/Helper';
import { RangeDate } from 'components/datetime';
import dayjs from 'dayjs';

const { Option } = Select;

class Filter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
            ddl: {
                rombel: []
            }
        };
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    render() {
        const { values } = this.props

        return (
            <Modal
                title="Filter"
                width={300}
                open={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Tutup</Button>
                    <Button type='primary' onClick={this.props.setTableFilterFromTemp}>Terapkan</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item label="Jenis Agenda">
                                <Select
                                    value={values?.jenis_agenda}
                                    allowClear
                                    name="jenis_agenda"
                                    placeholder="Jenis agenda"
                                    onChange={(e, newValue) => {
                                        this.props.setTempTableFilter("jenis_agenda", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {jenisAgendaOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Status Absensi">
                                <Select
                                    value={values?.status_absensi}
                                    allowClear
                                    name="status_absensi"
                                    placeholder="Status absensi"
                                    onChange={(e, newValue) => {
                                        this.props.setTempTableFilter("status_absensi", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {statusAbsensiOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Aktifitas">
                                <SelectPresenceActivitas
                                    value={values?.aktivitas}
                                    onChange={(e, newValue) => {
                                        this.props.setTempTableFilter("aktivitas", newValue ? newValue.value : null)
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Periode">
                                <RangeDate
                                    value={values?.tgl_awal && values?.tgl_akhir ? [dayjs(values?.tgl_awal), dayjs(values?.tgl_akhir)] : null}
                                    onChange={(e, val) => {
                                        if (val.length === 2 && (val[0] && val[1])) {
                                            let tglAwal = _getDate(val[0]);
                                            let tglAkhir = _getDate(val[1]);

                                            let _tglAwal = tglAwal.replace(/-/g, "").replace(/ /g, "").replace(/:/g, "");
                                            let _tglAkhir = tglAkhir.replace(/-/g, "").replace(/ /g, "").replace(/:/g, "");

                                            if (_tglAwal > _tglAkhir) {
                                                return message.warning("Periode yang dimasukkan tidak valid.");
                                            }

                                            this.props.setTempTableFilter("tgl_awal", val[0]);
                                            this.props.setTempTableFilter("tgl_akhir", val[1]);
                                        } else {
                                            this.props.setTempTableFilter("tgl_awal", null);
                                            this.props.setTempTableFilter("tgl_akhir", null);
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form >
            </Modal >
        )
    }
}

export default Filter;
