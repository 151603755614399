const columns = (action) => [
  {
    title: 'No',
    dataIndex: 'rownum',
    sorter: false,
    width: '2%',
  }, {
    title: 'Nama',
    dataIndex: 'siswa_nama',
    sorter: false,
  }, {
    title: 'Wali',
    dataIndex: 'wali_nama',
    sorter: false,
  }, {
    title: 'Tahun Ajaran',
    sorter: false,
    render: (a, row) => row.tahun_ajaran ? row.tahun_ajaran : "-",
  }, {
    title: 'Semester',
    sorter: false,
    render: (a, row) => row.tipe_semester ? row.tipe_semester : "-",
  }, {
    title: 'Rombel',
    sorter: false,
    render: (a, row) => row.rombel_nama ? row.rombel_nama : "-",
  }, {
    title: 'Phone (wa)',
    sorter: false,
    render: (a, row) => row.no_telp ? row.no_telp : "-",
  },
];

export default columns