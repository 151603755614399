import React from 'react';
import { connect } from 'react-redux'
import Config from "../../Config";
import { _getToken, isAuth, _error, _success, _validationFormMsg, _setAxios, _tableLogActivity } from '../../lib/Helper';

import { Input, Tooltip, Breadcrumb, Table, Row, Col, Divider, Popover, Modal, Button, Radio, Space, Menu, Dropdown, Tag } from 'antd';
import { PageHeader } from 'components/header';
import { UndoOutlined, CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined, SearchOutlined, DoubleRightOutlined, ArrowUpOutlined, ArrowDownOutlined, PlusOutlined, SortAscendingOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined, AntDesignOutlined, DownCircleOutlined, SettingOutlined } from '@ant-design/icons';
import axios from 'axios';
import AuthRedirect from '../../components/AuthRedirect'
import validate from 'validate.js';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';

const schema = {
    name: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
        length: {
            maximum: 100
        }
    }
};

class ConfRole extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tableOption: {
                sortField: "id",
                sortValue: "DESC",
            },
            data: [],
            search: "",
            pagination: {
                current: 1,
                pageSize: 10,
            },
            loading: false,
            visibleModalCreate: false,
            id: 0,
            values: {},
            errors: {},
            create: {
                visible: false,
                loading: false,
            },
            update: {
                visible: false,
                loading: false,
            },
            delete: {
                visible: false,
                loading: false,
            },

        };
    }

    componentDidMount() {
        this.fetchTable()
    }

    fetchTable = () => {
        var params = {
            search: this.state.search,
            pagination: { ...this.state.pagination },
            sorting: [{
                field: this.state.tableOption.sortField,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        }
        this.fetch(params);
    }


    handleTableChange = (pagination, filters, sorter) => {
        this.fetch({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            ...filters,
            search: this.state.search,
            sorting: [{
                field: this.state.tableOption.sortField,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        });
    };

    handleTableSearch = (e) => {
        const { value } = e.target;

        this.setState({
            search: value,
        });

        var params = {
            search: value,
            pagination: { ...this.state.pagination, current: 1 },
            sorting: [{
                field: this.state.tableOption.sortField,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        }
        this.fetch(params);

    };

    handleTableFilter = e => {
        this.setState({
            tableOption: {
                ...this.state.tableOption,
                sortField: e.target.value
            },
        });

        this.fetch({
            search: this.state.search,
            pagination: { ...this.state.pagination },
            sorting: [{
                field: e.target.value,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        });
    };

    handleTableSort = e => {
        this.setState({
            tableOption: {
                ...this.state.tableOption,
                sortValue: e.target.value
            },
        });

        this.fetch({
            search: this.state.search,
            pagination: { ...this.state.pagination },
            sorting: [{
                field: this.state.tableOption.sortField,
                sort: e.target.value,
                urutan: 1,
            }]
        });
    };

    fetch = async (params = {}) => {
        this.setState({ loading: true });
        const headers = {
            'Accept': 'application/json',
            'Authorization': _getToken()
        };

        axios.post(Config.api + '/role/table', JSON.stringify(params), { headers })
            .then((resp) => {
                this.setState({
                    loading: false,
                    data: resp.data.list,
                    pagination: {
                        ...params.pagination,
                        total: resp.data.info.total,
                    },
                });
            })
            .catch(function (error) {
                if (error.response.data.message) {
                    _error('topRight', 'Failed', error.response.data.message)
                }
            });
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setForm(name, value)
    };

    setForm = (name, value, row = {}) => {
        const { values } = this.state;
        values[name] = value;
        const errors = validate(values, schema);
        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    resetForm = () => {
        this.setState({
            values: {},
            errors: {},
        });
    };


    // CREATE START ------------------------------------------
    showModalCreate = () => {
        this.setState({
            create: {
                ...this.state.create,
                visible: true,
            },
        });
        this.resetForm()
    };

    hideModalCreate = () => {
        this.setState({
            create: {
                visible: false,
                loading: false,
            },

        });
    };

    createSubmit = (e) => {
        e.preventDefault();
        const { values } = this.state;
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }
        this.setState({
            create: {
                ...this.state.create,
                loading: true
            }
        });
        _setAxios("role", "POST", values).then(resp => {
            if (resp.status === true) {
                this.hideModalCreate()
                this.fetchTable()
                _success('topRight', 'Success', resp.data.message)
            } else {
                this.setState({
                    create: {
                        ...this.state.create,
                        loading: false
                    }
                });
            }
        })
    };

    // CREATE END ------------------------------------------

    // UPDATE START ------------------------------------------
    showModalUpdate = (row) => {
        this.setState({
            update: {
                visible: true,
                loading: false
            },

            id: row.id,
            values: {
                name: row.name,
            }
        });
    };

    hideModalUpdate = () => {
        this.setState({
            update: {
                visible: false,
                loading: false
            }
        });
    };

    updateSubmit = (e) => {
        e.preventDefault();
        const { values } = this.state;
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }

        this.setState({
            update: {
                ...this.state.update,
                loading: true
            }
        });
        _setAxios("role/" + this.state.id, "PUT", values).then(resp => {
            if (resp.status === true) {
                this.hideModalUpdate()
                this.fetchTable()
                _success('topRight', 'Success', resp.data.message)
            } else {
                this.setState({
                    update: {
                        ...this.state.update,
                        loading: false
                    }
                });
            }
        })

    };
    // UPDATE END ------------------------------------------

    // DELETE & RESTORE START ------------------------------------------

    onDelete = (row) => {
        Modal.confirm({
            title: 'Delete role ',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>Data role akan di hapus, Apakah Anda Yakin ?</>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    _setAxios("role/" + row.id, "DELETE").then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }
    onRestore = (row) => {
        Modal.confirm({
            title: 'Aktifkan kembali ',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>Data role akan diaktifkan kembali, Apakah Anda Yakin ?</>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    _setAxios("role/aktifkan/" + row.id, "PUT").then(resp => {
                        if (resp.status === true) {
                            setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                            _success('topRight', 'Success', resp.data.message)
                            this.fetchTable()
                        }
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }
    // DEELTE & RESTORE END ------------------------------------------




    render() {
        const access = this.props.privilege.access["/configuration/role"]
        if (!isAuth(this.props.privilege) || access === undefined) {
            return <AuthRedirect />
        }
        const action = access.action
        const { data, pagination, loading, values, errors, tableOption } = this.state;

        const sortComponent = <>
            <Radio.Group onChange={this.handleTableFilter} value={tableOption.sortField} name="sort_field">
                <Space direction="vertical">
                    <Radio value="id">Tanggal Pembuatan</Radio>
                    <Radio value="name">Nama</Radio>
                </Space>
            </Radio.Group>

            <Divider orientation="left"></Divider>

            <Radio.Group onChange={this.handleTableSort} value={tableOption.sortValue} name="sort_value">
                <Space direction="vertical">
                    <Radio value="ASC"><ArrowUpOutlined /> Ascending</Radio>
                    <Radio value="DESC"><ArrowDownOutlined /> Descending</Radio>
                </Space>
            </Radio.Group>
        </>

        const formInput = <>
            <Row gutter={[24, 24]}>
                <Col xs={24}>
                    <TextField
                        fullWidth
                        label="Nama"
                        size="small"
                        placeholder="Nama role"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="name"
                        type="text"
                        onChange={this.handleChange}
                        value={values.name || ''}
                        error={this.hasError('name')}
                        helperText={
                            this.hasError('name') ? errors.name[0] : null
                        }
                    />
                </Col>
            </Row>
        </>

        const modalCreate = <Modal
            title="Tambah Role"
            visible={this.state.create.visible}
            onCancel={this.hideModalCreate}
            footer={<>
                <Button onClick={this.hideModalCreate}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.createSubmit} loading={this.state.create.loading}>Simpan</Button>
            </>}
        >
            <form autoComplete="off" onSubmit={this.createSubmit}>
                {formInput}
            </form>
        </Modal>

        const modalUpdate = <Modal
            title="Update Role"
            onCancel={this.hideModalUpdate}
            visible={this.state.update.visible}
            footer={<>
                <Button onClick={this.hideModalUpdate}>Batal</Button>
                <Button type="primary" htmlType="submit" id="btnUpdateSubmit" onClick={this.updateSubmit} loading={this.state.update.loading}>Simpan</Button>
            </>}

        >
            <form autoComplete="off" onSubmit={this.updateSubmit}>
                {formInput}
            </form>
        </Modal>

        const modalDelete = <Modal
            title={<><ExclamationCircleOutlined /> Confirm</>}
            visible={this.state.delete.visible}
            onCancel={this.hideModalDelete}
            footer={<>
                <Button onClick={this.hideModalDelete}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.deleteSubmit} loading={this.state.delete.loading}>Simpan</Button>
            </>}
        >
            Apakah Anda Yakin?

        </Modal>

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Konfigurasi</Breadcrumb.Item>
                        <Breadcrumb.Item>Role</Breadcrumb.Item>
                    </Breadcrumb>}
                    extra={action.create === true ? <Tooltip title="Tambah">
                        <Button type='primary' onClick={this.showModalCreate}><PlusOutlined /> Tambah</Button>
                    </Tooltip> : ""}
                />
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <PageHeader
                        className="site-page-header"
                        title="Role"
                        subTitle="Data konfigurasi role"
                    />
                    <Row gutter={[16, 16]}>
                        <Col xs={16} sm={18} md={20} lg={21}>
                            <Input
                                name="search"
                                className='search-table'
                                placeholder="Search..."
                                prefix={<SearchOutlined className="site-form-item-icon" />}
                                onChange={this.handleTableSearch}
                                value={this.state.search || ""}
                                suffix={
                                    <Tooltip title="Cari berdasarkan nama">
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                            />
                        </Col>
                        <Col xs={8} sm={6} md={4} lg={3} className="text-align-right">
                            <Popover placement="bottom" content={sortComponent} trigger="click">
                                <Button block><SortAscendingOutlined /> Sort</Button>
                            </Popover>
                        </Col>
                    </Row>

                    <Divider orientation="left"></Divider>

                    <Row>
                        <Col span={24}>
                            <Table
                                columns={[
                                    {
                                        title: 'No',
                                        dataIndex: 'rownum',
                                        width: '3%',
                                    },
                                    {
                                        title: 'Name',
                                        dataIndex: 'name',
                                    },
                                    {
                                        title: 'Status',
                                        dataIndex: 'ttl',
                                        render: (a, row) => <>
                                            {row.is_deleted === "0" ? <Tag color="#0d5735 "><CheckCircleOutlined /> Aktif</Tag> : <Tag color="rgb(13 79 53 / 48%)"><CloseCircleOutlined /> Tidak Aktif</Tag>}
                                        </>,
                                        width: '10%',
                                    },
                                    {
                                        title: <SettingOutlined />,
                                        key: 'operation',
                                        align: "center",
                                        width: '2%',
                                        render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                                            {action.update === true && row.is_deleted === "0" ? (<Menu.Item key={0} onClick={this.showModalUpdate.bind(this, row)}><EditOutlined /> Update</Menu.Item>) : ""}
                                            {action.update === true && row.is_deleted === "0" ? (<Menu.Item key={1} ><Link to={"/configuration/privilege/" + row.id}><AntDesignOutlined /> Assign Privilege</Link></Menu.Item>) : ""}
                                            {action.delete === true && row.is_deleted === "0" ? (<Menu.Item key={2} onClick={this.onDelete.bind(this, row)}><DeleteOutlined /> Delete</Menu.Item>) : ""}
                                            {action.restore === true && row.is_deleted === "1" ? (<Menu.Item key={3} onClick={this.onRestore.bind(this, row)}><UndoOutlined /> Pulihkan</Menu.Item>) : ""}
                                        </Menu>}>
                                            <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                                        </Dropdown>,
                                    },
                                    {
                                        title: 'LogActivity',
                                        key: 'operation',
                                        width: '10%',
                                        render: (a, row) => _tableLogActivity(row),
                                    },
                                ]}
                                rowKey={record => record.id}
                                dataSource={data}
                                pagination={pagination}
                                loading={loading}
                                onChange={this.handleTableChange}
                                size="small"
                            />
                        </Col>
                    </Row>
                </div>

                {modalCreate}

                {modalUpdate}

                {modalDelete}

            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(ConfRole);
