import React from 'react';
import { connect } from 'react-redux'
import { _setAxios, _success, _tableLogActivity, getParamTable, _validationFormMsg, _getBase64Upload, _getBase64, getParamTempTableFiltering, _beforeUploadPdf } from '../../../lib/Helper';
import { schemaTataTertib } from './schema';
import validate from 'validate.js';
import { Table, Row, Col, Divider, Popover, Button, Radio, Space, Menu, Dropdown, Input, Tooltip, Modal, Upload, Checkbox, Form, Pagination } from 'antd';
import { PageHeader } from 'components/header';
import { SaveOutlined, InfoCircleOutlined, SearchOutlined, PlusOutlined, SortAscendingOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined, InboxOutlined, DownloadOutlined, FilterOutlined, SettingOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';
import SelectTahunAjaran from '../../../components/select/SelectTahunAjaran';

const { Dragger } = Upload;

const tableOptions = {
    sorts: [{
        value: "ASC",
        label: "Ascending",
    }, {
        value: "DESC",
        label: "Descending",
    }],
    fields: [{
        value: "id",
        label: "Tanggal Pembuatan",
    }, {
        value: "nama",
        label: "Nama",
    }]
}

class TataTertib extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},

            table: {
                loading: false,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [],
                filtering: {
                    tahun_ajaran: null
                },
            },
            form: {
                visible: false,
                loading: false,
            },
            filter: {
                visible: false,
                values: {},
            },
            fileList: [],
            validateFile: false,
            file_active: null
        };
    }

    componentDidMount() {
        this.fetchTable()
        this.getFileActive()
    }

    getFileActive = () => {
        _setAxios("tatibsi/dokumen/file", "GET").then(resp => {
            if (resp.status === true) {
                const data = resp.data.data
                this.setState({
                    file_active: data ? data.main_path + data.file : null
                })
            }
        })
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("tatibsi/dokumen/table", "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        const errors = validate(values, schemaTataTertib);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return this.setValidate(values)
    }

    setValidate = (values) => {
        const errors = validate(values, schemaTataTertib);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    // HANDLE FILE START --------------------------------------
    beforeUploadFile = (file) => {
        if (!_beforeUploadPdf(file)) {
            this.setState({
                validateFile: false,
            })
            return false
        } else {
            this.setState({
                validateFile: true,
            })
        }
        return true
    };

    handleChangeFile = ({ fileList }) => {
        if (this.state.validateFile === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });

            this.setState({
                fileList: fileList
            })
        } else {
            this.setState({
                fileList: null
            })
        }
    };
    // HANDLE FILE END --------------------------------------


    // CREATE START ------------------------------------------

    hideModalForm = (row) => {
        this.setState({
            form: {
                ...this.state.form,
                visible: false
            },

            values: {},
            errors: {},
            fileList: null
        });
    };

    showModalForm = (row) => {
        if (row.id) {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },

                values: {
                    id: row.id ? row.id : null,
                    nama: row.nama ? row.nama : null,
                    tahun_ajaran_id: row.tahun_ajaran_id ? row.tahun_ajaran_id : null,
                    status: row.status === "Tampilkan" ? true : false,
                },
            });
        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },
            });
        }
    };

    onSubmitForm = async (e) => {
        e.preventDefault();
        const { values } = this.state
        const { privilege } = this.props
        const { tahun_ajaran } = privilege

        //Validation 
        let validation = this.setValidate(values, schemaTataTertib)
        if (validation !== undefined) {
            return _validationFormMsg(validation)
        }

        // File base64
        let fileList = this.state.fileList
        let file = null
        if (fileList && fileList.length > 0) {
            if (fileList[0].url && (fileList[0].url.includes("https://") || fileList[0].url.includes("http://"))) {
            } else {
                file = await _getBase64(fileList[0].originFileObj)
                file = _getBase64Upload(file)
            }
        }

        // Payload
        let param = {
            nama: values.nama ? values.nama : null,
            status: values.status ? "Tampilkan" : null,
            tahun_ajaran_id: tahun_ajaran?.id,
            file: file
        }

        // Define Network
        let method = "POST"
        let endpoint = "tatibsi/dokumen"
        if (values.id) {
            param.tahun_ajaran_id = values.tahun_ajaran_id ? values.tahun_ajaran_id : null
            method = "PUT"
            endpoint = "tatibsi/dokumen/" + values.id
        }

        this.setState({
            form: {
                ...this.state.form,
                loading: true
            }
        });
        _setAxios(endpoint, method, param).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.hideModalForm()
                this.fetchTable()
            }

            this.setState({
                form: {
                    ...this.state.form,
                    loading: false
                }
            });
        })
    };

    // CREATE END ------------------------------------------


    onDelete = (row) => {
        Modal.confirm({
            title: 'Hapus Data',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>Data akan dihapus dari list, Apakah Anda Yakin ?</>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    _setAxios("tatibsi/dokumen/" + row.id, "DELETE").then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }

    // FILTER START ------------------------------------------

    hideModalFilter = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                visible: false
            },
        });
    };

    showModalFilter = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                visible: true
            },
        });
    };

    setTempTableFilter = (name, value) => {
        let values = this.state.filter.values
        values[name] = value

        this.setState({
            filter: {
                ...this.state.filter,
                values: values
            }
        })
    };

    setTableFilterFromTemp = () => {
        const params = getParamTempTableFiltering(this.state.table, this.state.filter.values)
        this.fetch(params)
        this.hideModalFilter()
    };
    // FILTER END ------------------------------------------

    render() {
        const { table, values, errors } = this.state;

        const sortComponent = <>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort_field", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].field : ""} name="sort_field">
                <Space direction="vertical">
                    {tableOptions.fields.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
            <Divider orientation="left"></Divider>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].sort : ""} name="sort_value">
                <Space direction="vertical">
                    {tableOptions.sorts.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
        </>

        const modalFilter = <Modal
            title="Filter Tata Tertib"
            visible={this.state.filter.visible}
            onCancel={this.hideModalFilter}
            footer={<>
                <Button onClick={this.hideModalFilter}>Tutup</Button>
                <Button type='primary' onClick={this.setTableFilterFromTemp}>Terapkan</Button>
            </>}
        >
            <Form
                name="basic"
                autoComplete="off"
                layout="vertical"
            >
                <Form.Item label="Tahun Ajaran">
                    <SelectTahunAjaran
                        onChange={(val) => {
                            this.setTempTableFilter("tahun_ajaran", val ? val : null)
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>

        const modalForm = <Modal
            title="Form Dokumen Tata Tertib"
            visible={this.state.form.visible}
            // width={700}
            onCancel={this.hideModalForm}
            footer={<>
                <Button onClick={this.hideModalForm}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.onSubmitForm} loading={this.state.form.loading} icon={<SaveOutlined />}>Simpan</Button>
            </>}
        >
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <TextField
                        fullWidth
                        label="Nama Dokumen *"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="nama"
                        type="text"
                        onChange={this.handleChange}
                        value={values.nama || ''}
                        error={this.hasError('nama')}
                        helperText={
                            this.hasError('nama') ? errors.nama[0] : null
                        }
                    />
                </Col>
                <Col xs={24}>
                    <Checkbox
                        checked={values.status}
                        onChange={(e) => {
                            this.handleChangeSetValue("status", e.target.checked)
                        }}
                    >Tampilkan (Centang jika ingin menampilkan file ini sebagai default)</Checkbox>
                </Col>
                <Col xs={24}>
                    <Dragger
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        fileList={this.state.fileList}
                        onChange={this.handleChangeFile}
                        beforeUpload={this.beforeUploadFile}
                        maxCount={1}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Klik atau seret file ke area ini</p>
                        <p className="ant-upload-hint">File yang diperbolehkan untuk diunggah: PDF</p>
                        <p className="ant-upload-hint">File yang diperbolehkan maksimal 2MB</p>
                    </Dragger>
                </Col>
            </Row>
        </Modal>

        const dataTable = <Table
            columns={[
                {
                    title: 'No',
                    dataIndex: 'rownum',
                    width: '3%',
                },
                {
                    title: 'Nama Dokumen',
                    dataIndex: 'nama',
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    render: (a, row) => row.status ? row.status : "-"
                },
                {
                    title: 'File',
                    dataIndex: 'file',
                    render: (a, row) => row.file ? <a rel="noopener noreferrer" href={row.main_path + row.file} style={{ fontSize: 11 }} >Download <DownloadOutlined /></a> : "-",
                },
                {
                    title: <SettingOutlined />,
                    align: "center",
                    key: 'operation',
                    width: '2%',
                    render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                        {this.props.action.update ? <Menu.Item key={0} onClick={this.showModalForm.bind(this, row)}><EditOutlined /> Update</Menu.Item> : ""}
                        {this.props.action.delete ? <Menu.Item key={1} onClick={this.onDelete.bind(this, row)}><DeleteOutlined /> Delete</Menu.Item> : ""}
                    </Menu>}>
                        <div className="ant-dropdown-link tb-action">
                            <Button type='primary'>Opsi</Button>
                        </div>
                    </Dropdown>,
                },
                {
                    title: 'LogActivity',
                    key: 'operation',
                    width: '10%',
                    render: (a, row) => _tableLogActivity(row),
                },
            ]}
            rowKey={record => record.id}
            dataSource={table.data}
            pagination={false}
            loading={table.loading}
            size="small"
        />

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="Tata Tertib"
                    extra={
                        this.props.action.create ? 
                        <>
                            <Tooltip title="Tambah">
                                <Button type='primary' icon={<PlusOutlined />} onClick={this.showModalForm}> Tambah</Button>
                            </Tooltip>
                        </> : null
                    }
                />
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={19} md={19} >
                        <Input
                            name="search"
                            className='search-table'
                            placeholder="Search..."
                            prefix={<SearchOutlined className="site-form-item-icon" />}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    const params = getParamTable("search", table, e.target.value)
                                    this.fetch(params)
                                }
                            }}
                            suffix={
                                <Tooltip title="Cari berdasarkan nama">
                                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                </Tooltip>
                            }
                        />
                    </Col>
                    <Col xs={24} sm={6} md={5}>
                        <Button.Group className='ant-btn-group-fullwidth'>
                            <Button className='btn-border-radius-left' onClick={this.showModalFilter}><FilterOutlined /></Button>
                            <Popover placement="bottom" content={sortComponent} trigger="click">
                                <Button block className='btn-border-radius-right'><SortAscendingOutlined /> Sort</Button>
                            </Popover>
                        </Button.Group>
                    </Col>
                </Row>

                <Divider orientation="left"></Divider>

                <Row>
                    <Col span={24}>
                        {dataTable}
                    </Col>
                    <Col xs={24} className="table-pagination">
                        <Pagination
                            size="small"
                            current={table.pagination.current}
                            pageSize={table.pagination.pageSize}
                            total={table.pagination.total}
                            showTotal={() => `Total ${table.pagination.total} items`}
                            showSizeChanger
                            onChange={(current, pageSize) => {
                                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                                const params = getParamTable("change", table, null, null, pagination)
                                this.fetch(params)
                            }}
                        />
                    </Col>
                    <Col span={24}>
                        <Divider />
                    </Col>
                    <Col span={24}>
                        <embed src={this.state.file_active} type="application/pdf" style={{
                            width: "100%",
                            height: 720
                        }} />
                    </Col>
                </Row>

                {modalForm}
                {modalFilter}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(TataTertib);
