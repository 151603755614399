import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { _setAxios } from '../../lib/Helper';

async function fetchList(modul, tingkatanKelas, ubudiyahKategoriID) {

  if (!modul || !tingkatanKelas || !ubudiyahKategoriID) {
    return []
  }

  return await _setAxios("ubudiyah/aspek/dropdown", "POST", {
    modul: modul,
    tingkatan_kelas: tingkatanKelas,
    ubudiyah_kategori_id: ubudiyahKategoriID
  })
    .then(body => {
      let results = []
      body.data?.data?.forEach(row => {
        results.push({
          label: row.nama,
          value: row.id,
        })
      });

      return results
    })
}

const SelectAspekPenilaianUbudiyah = ({ ...props }) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    fetchList(props.modul, props.tingkatanKelas, props.ubudiyahKategoriID).then((newOptions) => {
      setOptions(newOptions);
    });
  }, [props.modul, props.tingkatanKelas, props.ubudiyahKategoriID]);

  return (
    <Select
      {...props}
      fetchOptions={fetchList}
      placeholder="Pilih aspek penilaian"
      style={{
        width: '100%',
      }}
      options={options}
    />
  );
};
export default SelectAspekPenilaianUbudiyah;
