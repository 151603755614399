import { _tableLogActivity } from "lib/Helper";
import Action from "./Action";
import { SettingOutlined } from "@ant-design/icons";

const columns = (action, modalForm, modalDelete) => [
  {
    title: 'No',
    dataIndex: 'rownum',
    sorter: false,
    width: '2%',
  }, {
    title: 'Nama Rombel',
    dataIndex: 'nama',
    sorter: false,
  }, {
    title: 'Tingkat',
    dataIndex: 'tingkatan_kelas',
    sorter: false,
  }, {
    title: 'Wali Kelas',
    dataIndex: 'tk_nama',
    sorter: false,
  }, {
    title: 'Ruangan',
    dataIndex: 'ruangan_nama',
    sorter: false,
  }, {
    title: 'Kurikulum',
    dataIndex: 'kurikulum_nama',
    sorter: false,
  }, {
    title: 'Total Siswa',
    dataIndex: 'total_siswa',
    sorter: false,
  }, {
    title: <SettingOutlined />,
    align: "center",
    key: 'operation',
    width: '2%',
    render: (a, row) => <Action row={row} action={action} modalForm={modalForm} modalDelete={modalDelete}/>,
  },
  {
    title: 'LogActivity',
    key: 'logactivity',
    width: '10%',
    render: (a, row) => _tableLogActivity(row),
  },
];

export default columns