import React from 'react';
import { Button, Dropdown } from 'antd';
import { CloseCircleOutlined, DownCircleOutlined, SendOutlined } from '@ant-design/icons';

class Action extends React.Component {
    render() {
        const { row, action, modalSendNotif, modalDelete } = this.props;
        const items = [];

        if (!action.delete && !action.send_notification) {
            return "-"
        }

        // if (action.send_notification) {
            items.push({
                key: '1',
                label: "Send Notification",
                icon: <SendOutlined rotate={-40}/>,
                onClick: () => {
                    modalSendNotif(true, row)
                },
            })
        // }
        if (action.delete) {
            items.push({
                key: '1',
                label: "Delete",
                icon: <CloseCircleOutlined />,
                onClick: () => {
                    modalDelete(true, row)
                },
            })
        }

        return (
            <Dropdown
                trigger={['click']}
                menu={{ items }}
            >
                <Button type="primary">
                    Opsi <DownCircleOutlined style={{ fontSize: 11 }} />
                </Button>
            </Dropdown>
        );
    }
}


export default Action;
